.navbar-item img {
  max-height: 2rem;
}

.logo-text {
  margin-left: 10px;
  font-weight: bold;
  font-size: 1.5rem;
}

.button.is-normal-segment {
  width: 100%;
  background-color: #00403f;
  border-color: transparent;
  color: #fff;
  border-radius: 0px;
}

.button.is-outlined-segment {
  width: 100%;
  background-color: #fff;
  border-color: #00403f;
  color: #00403f;
  border-radius: 0px;
}

.button.is-normal-sub-segment {
  width: 100%;
  background-color: #014d4b;
  border-color: transparent;
  color: #fff;
  border-radius: 0px;
}

.button.is-outlined-sub-segment {
  width: 100%;
  background-color: #fff;
  border-color: #014d4b;
  color: #014d4b;
  border-radius: 0px;
}

.button.is-normal-hard-skill {
  width: 100%;
  background-color: #016160;
  border-color: transparent;
  color: #fff;
  border-radius: 0px;
}

.button.is-outlined-hard-skill {
  width: 100%;
  background-color: #fff;
  border-color: #016160;
  color: #016160;
  border-radius: 0px;
}

.button.is-normal-method {
  width: 100%;
  background-color: #0fa4aa;
  border-color: transparent;
  color: #fff;
  border-radius: 0px;
}

.button.is-outlined-method {
  width: 100%;
  background-color: #fff;
  border-color: #0fa4aa;
  color: #0fa4aa;
  border-radius: 0px;
}

.button.is-normal-experience {
  width: 100%;
  background-color: #6eb7b6;
  border-color: transparent;
  color: #fff;
  border-radius: 0px;
}

.button.is-outlined-experience {
  width: 100%;
  background-color: #fff;
  border-color: #6eb7b6;
  color: #6eb7b6;
  border-radius: 0px;
}

.propagile-link {
  display: inline-block;
  color: #f44336 !important;
  font-weight: bold;
  text-decoration: none;
}

.propagile-link::after {
  content: "";
  display: block;
  width: 0%;
  height: 2px;
  background: #f44336;
  transition: width 0.3s;
}

.propagile-link:hover::after {
  width: 100%;
}

.propagile-link-white {
  display: inline-block;
  color: #fff !important;
  text-decoration: none;
}

.propagile-link-white::after {
  content: "";
  display: block;
  width: 0%;
  height: 2px;
  background: #fff;
  transition: width 0.3s;
}

.propagile-link-white:hover::after {
  width: 100%;
}

.propagile-link-black {
  display: inline-block;
  color: #000 !important;
  text-decoration: none;
}

.propagile-link-black::after {
  content: "";
  display: block;
  width: 0%;
  height: 2px;
  background: #000;
  transition: width 0.3s;
}

.propagile-link-black:hover::after {
  width: 100%;
}

.link-black {
  display: inline-block;
  color: #000 !important;
  text-decoration: none;
}

.link-dark {
  display: inline-block;
  color: #4a4a4a !important;
  text-decoration: none;
}

.link-red {
  display: inline-block;
  color: #f44336 !important;
  text-decoration: none;
}

.filter-dropdown-item-0 {
  position: absolute !important;
  width: 20%;
  left: 4%;
  top: 100%;
  z-index: 5;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  background-color: #fff;
}

.filter-dropdown-item-1 {
  position: absolute !important;
  width: 20%;
  left: 15.8%;
  top: 100%;
  z-index: 5;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  background-color: #fff;
}

.filter-dropdown-item-2 {
  position: absolute !important;
  width: 20%;
  left: 27.3%;
  top: 100%;
  z-index: 5;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  background-color: #fff;
}

.filter-dropdown-item-3 {
  position: absolute !important;
  width: 20%;
  left: 39%;
  top: 100%;
  z-index: 5;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  background-color: #fff;
}

.filter-dropdown-item-4 {
  position: absolute !important;
  width: 20%;
  left: 50.7%;
  top: 100%;
  z-index: 7;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  background-color: #fff;
}

.filter-dropdown-item-5 {
  position: absolute !important;
  width: 20%;
  left: 62.2%;
  top: 100%;
  z-index: 7;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  background-color: #fff;
}

.filter-dropdown-item-6 {
  position: absolute !important;
  width: 15%;
  left: 81.2%;
  top: 100%;
  z-index: 7;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  background-color: #fff;
}

.title.is-5 {
  margin-bottom: 1.25rem !important;
}

.subtitle.is-6 {
  margin-bottom: 1.25rem !important;
}

.bottom-right-align-content {
  display: flex;
  align-content: flex-end;
  align-items: flex-end;
  justify-content: flex-end;
  justify-items: flex-end;
}

.top-right-align-content {
  display: flex;
  align-content: flex-start;
  align-items: flex-start;
  justify-content: flex-end;
  justify-items: flex-end;
}

.bottom-left-align-content {
  display: flex;
  align-content: flex-end;
  align-items: flex-end;
  justify-content: flex-start;
  justify-items: flex-start;
}

.bottom-center-align-content {
  display: flex;
  align-content: flex-end;
  align-items: flex-end;
  justify-content: center;
  justify-items: center;
}

.center-left-align-content {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  justify-items: flex-start;
}

.center-align-content {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
}

.top-center-align-content {
  display: flex;
  align-content: flex-start;
  align-items: flex-start;
  justify-content: center;
  justify-items: center;
}

.checked {
  color: orange;
}

.chevron-button {
  position: absolute;
  top: 38%;
  display: block;
  height: 30px;
  width: 30px;
  border-radius: 15px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  border: transparent;
}

.slick-prev {
  left: -15px;
  z-index: 2;
}

.slick-next {
  right: -15px;
  z-index: 2;
}

.slick-next::before {
  height: 20px;
  width: 20px;
  align-self: flex-start;
  content: url("./assets/images/rightScroll.svg");
  opacity: 1;
}

.slick-prev::before {
  height: 20px;
  width: 20px;
  content: url("./assets/images/leftScroll.svg");
  opacity: 1;
}

.slick-slide,
.slick-slide * {
  outline: none !important;
}

.overlay-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: transparent;
  color: #000;
  font-weight: 600;
  font-size: 12px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
  outline: none;
}

.thumbs-up-btn {
  position: absolute;
  bottom: -2%;
  left: 10%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: transparent;
  color: #000;
  font-size: 18px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
  outline: none;
}

.thumbs-down-btn {
  position: absolute;
  bottom: -2%;
  left: 90%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: transparent;
  color: #000;
  font-size: 18px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
  outline: none;
}

.root {
  min-height: 100vh;
}

.blink {
  -webkit-animation: blink 2s linear infinite;
  -moz-animation: blink 2s linear infinite;
  -ms-animation: blink 2s linear infinite;
  -o-animation: blink 2s linear infinite;
  animation: blink 2s linear infinite;
}
@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  50.01% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  50.01% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@-ms-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  50.01% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  50.01% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  50.01% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.image.is-256x256 {
  height: 256px;
  width: 256px;
}

/************New Design Css Start**************/

.image.is-196x196 {
  height: 196px;
  width: 196px;
}

.MuiRadio-colorSecondary.Mui-checked {
  color: #fcc75c /* #e99709 */ !important;
}


