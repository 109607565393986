.main {
  width: 95%;
  margin: 2em;
}

.profileInformationPanel {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: start;
  gap: 1em;
}

.promotionStepOne {
  padding: 25px
}

.promotionStepOne>button {
  width: 40%;
  background-color: #1a67a3 !important;
}

.promotionStepTwo {
  padding: 25px
}

.itemsProfileInformationPanel {
  border: 1px solid rgb(185, 185, 185);
  background-color: rgb(250, 250, 250);
  padding: 10px 25px;
  width: 20vw;
  min-height: 25vh;
  border-left: 5px solid #1a67a3;
  /* border-radius: 5px; */
}

.itemsReputationPanel {
  border: 1px solid rgb(185, 185, 185);
  background-color: rgb(250, 250, 250);
  padding: 10px 25px;
  width: auto;
  min-height: 25vh;
  border-left: 5px solid #1a67a3;
  border-radius: 5px;
}

.informationTitle {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: 1px solid rgb(185, 185, 185);
  margin-bottom: 15px;
}

.informationTitle>span {
  font-size: 18px;
  font-weight: 600;
  color: rgb(114, 114, 114);
}

.informationTitle>span:last-child {
  font-size: 15px;
  font-weight: 600;
  color: rgb(114, 114, 114);
}

.informationParaph {
  font-size: 12px;
  font-weight: 600;
  color: rgb(114, 114, 114);
}

.informationTitle>i {
  font-style: normal;
  color: #1a67a3;
  cursor: pointer;
}

.fields {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 5px;
}

.fieldsPassword>span:first-child {
  color: red;
}

.fields>span {
  font-size: 15px;
}

.fields>span:first-child {
  font-weight: 600;
  color: rgb(114, 114, 114);
}

.closeEdit {
  height: 26vh;
}

.openEdit[data="profile"] {
  height: auto;
  padding: 20px 25px;

}

.openEdit[data="company"] {
  height: auto;
  padding: 20px 25px;
}

.openEdit[data="location"] {
  height: auto;
  padding: 20px 25px;
}

.openEdit[data="vendor"] {
  height: auto;
  padding: 20px 25px;
}

/* Forms profile */

.inputProfilePanel {
  height: 40px !important;
  border: 0px !important;
  border-bottom: 1px solid gray !important;
  background-color: rgb(250, 250, 250) !important;
}



.labelInput {
  position: absolute;
  top: 15px;
  left: 15px;
  font-size: 15px !important;
  color: rgb(167, 167, 167) !important;
  pointer-events: none;
  /* Esto evita que el label interfiera con el input */
  transition: all 0.3s ease;
  /* Agregar una transición suave */
}

/* Estilo del placeholder cuando el input está enfocado o tiene valor */
.inputProfilePanel:focus+.labelInput,
.inputProfilePanel:not(:placeholder-shown)+.labelInput {
  top: -10px;
  /* Mueve el label hacia arriba cuando el input está enfocado o tiene valor */
  left: 0;

  font-size: 10px !important;
  /* Reduce el tamaño del placeholder cuando el input está enfocado o tiene valor */
  color: #333;
  /* Cambia el color del placeholder cuando el input está enfocado o tiene valor */
}

.inputProfilePanel::placeholder {
  font-size: 12px;
}



.divActionsButtons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 1em;
}

.divActionsButtons>button {
  flex: 1 0 8rem;
  /* width: 1rem !important; */
}

.modalSubscription{
  width: 25vw!important;
}
.modalPassword{
  width: 25vw!important;
}

@media (max-width: 992px) {
  .main {
    width: 100%;
    margin: 0px;
  }

  .itemsProfileInformationPanel {
    width: 100%;
  }

  .promotionStepOne>button {
    width: 100% !important;
  }

  .modalSubscription{
    width: 90%!important;
  }
  .modalPassword{
    width: 90%!important;
  }


}