.main {
  width: 95%;
  margin: 2em;
}

.profileInformationPanel {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1em;
}

.promotionStepOne {
  padding: 25px
}

.promotionStepOne>button {
  width: 40%;
  background-color: #1a67a3 !important;
}

.promotionStepTwo {
  padding: 25px
}

.itemsProfileInformationPanel {
  border: 1px solid rgb(185, 185, 185);
  background-color: rgb(250, 250, 250);
  padding: 10px 25px;
  width: 30rem;
  min-height: 25vh;
  border-left: 5px solid #1a67a3;
  border-radius: 5px;
}

.itemsReputationPanel {
  border: 1px solid rgb(185, 185, 185);
  background-color: rgb(250, 250, 250);
  padding: 10px 25px;
  width: auto;
  min-height: 25vh;
  border-left: 5px solid #1a67a3;
  border-radius: 5px;
}

.informationTitle {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: 1px solid rgb(185, 185, 185);
  margin-bottom: 15px;
}

.informationTitle>span {
  font-size: 18px;
  font-weight: 600;
  color: rgb(114, 114, 114);
}

.informationTitle>span:last-child {
  font-size: 15px;
  font-weight: 600;
  color: rgb(114, 114, 114);
}

.informationParaph {
  font-size: 12px;
  font-weight: 600;
  color: rgb(114, 114, 114);
}

.informationTitle>i {
  font-style: normal;
  color: #1a67a3;
  cursor: pointer;
}

.fields {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 5px;
}

.fields>span {
  font-size: 15px;
}

.fields>span:first-child {
  font-weight: 600;
  color: rgb(114, 114, 114);
}

.closeEdit {
  height: 25vh;
}

.openEdit[data="profile"] {
  height: auto;
  padding: 20px 25px;

}

.openEdit[data="company"] {
  height: auto;
  padding: 20px 25px;
}

.openEdit[data="location"] {
  height: auto;
  padding: 20px 25px;
}

/* Forms profile */

.inputProfilePanel {
  height: 40px !important;
  border: 0px !important;
  border-bottom: 1px solid gray !important;
  background-color: rgb(250, 250, 250) !important;
}

.inputPasswordPanel {
  height: 40px !important;
  border: 0px !important;
  /* border-bottom: 1px solid gray !important; */
  border: 1px solid rgb(209, 209, 209)!important;
  background-color: rgb(255, 255, 255) !important;
}

.labelInput {
  position: absolute;
  top: 15px;
  left: 15px;
  font-size: 15px !important;
  color: rgb(167, 167, 167) !important;
  pointer-events: none;
  /* Esto evita que el label interfiera con el input */
  transition: all 0.3s ease;
  /* Agregar una transición suave */
}
.passwordLabelInput {
  position: absolute;
  top: 10px;
  left: 15px;
  font-size: 12px !important;
  font-weight: 500 !important;
  color: rgb(167, 167, 167) !important;
  pointer-events: none;
  /* Esto evita que el label interfiera con el input */
  transition: all 0.3s ease;
  /* Agregar una transición suave */
}

/* Estilo del placeholder cuando el input está enfocado o tiene valor */
.inputProfilePanel:focus+.labelInput,
.inputProfilePanel:not(:placeholder-shown)+.labelInput {
  top: -10px!important;
  /* Mueve el label hacia arriba cuando el input está enfocado o tiene valor */
  left: 0;

  font-size: 10px !important;
  /* Reduce el tamaño del placeholder cuando el input está enfocado o tiene valor */
  color: #333;
  /* Cambia el color del placeholder cuando el input está enfocado o tiene valor */
}

.inputProfilePanel::placeholder {
  font-size: 12px;
}

.inputPasswordPanel:focus+.passwordLabelInput,
.inputPasswordPanel:not(:placeholder-shown)+.passwordLabelInput {
  top: -10px;
  /* Mueve el label hacia arriba cuando el input está enfocado o tiene valor */
  left: 0;

  font-size: 10px !important;
  /* Reduce el tamaño del placeholder cuando el input está enfocado o tiene valor */
  color: #333;
  /* Cambia el color del placeholder cuando el input está enfocado o tiene valor */
}

.inputPasswordPanel::placeholder {
  font-size: 12px;
}

.divActionsButtons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 1em;
}

.divActionsButtons>button {
  flex: 1 0 8rem;
  /* width: 1rem !important; */
}


.heading {
  display: flex;
  justify-content: space-between;
  color: rgb(55, 55, 55);
  font-size: 18px;
  font-weight: bold;
  /* border-bottom: 1px solid black; */
}

.heading>i {
  font-style: normal;
  font-size: 15px;
  color: #1a67a3;
  cursor: pointer;
}


.heading {
  color: rgb(218, 181, 17);
  font-size: 22px;
  font-weight: bold;
  text-align: center;
}

.arrow-icon {
  display: block;
}

.informationPrice>h2 {
  margin: 0px;
  font-family: 'Monserrat';
  font-weight: 500;
}

.informationPrice>p {
  font-family: 'Monserrat';
  font-size: 18px;
  font-weight: 500;
}

.fields>.active {
  background-color: #02a102;
  padding: 0px 5px;
  border-radius: 5px;
  color: white;
}

.unsubscribe>span {
  color: rgb(207, 1, 1) !important;
  text-decoration: underline;
  font-size: 12px;
  cursor: pointer;
}

@media (max-width: 992px) {
  .main {
    width: 100%;
    margin: 0px;
  }

  .itemsProfileInformationPanel {
    width: 100%;
  }


  .heading {
    color: rgb(218, 181, 17);
    font-size: 22px;
    font-weight: bold;
    text-align: center;
  }

  .promotionStepOne>button {
    width: 100% !important;
  }

  .arrow-icon {
    display: none !important;
  }

  .promotionStepTwo {
    padding: 0px 10px 0px 0px
  }


}