.title-panel {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 1em;
}

.title-panel>div>span>i {
    font-size: 15px;
    font-style: normal;
    color: #1a67a3;
    cursor: pointer;
    margin: 0px 10px;
}

.panel-endorsement {
    padding: 20px 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 5px;
    border: 1px solid rgb(161, 161, 161);
    border-left: 5px solid #1a67a3;
    height: auto;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
    background-color: rgb(250, 250, 250);
}

.panel-endorsement>h1 {
    margin-top: 15px;
    font-size: 20px;
    padding: 0px 25px;
}

.panel-endorsement>h1>span {
    font-size: 13px;
    font-style: normal;
}

.panel-invitation {
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 1em;

}

.invited-form {
    /* flex: 0.8 0 8rem; */
    width: 50%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 1em;
    padding: 0px 25px;
    border-right: 1px solid gray;
}

.invited-list {
    /* flex: 1 0 8rem; */
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 1em;
    width: 48%;
}

.invited-items {
    flex: 1 0 8rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    grid-gap: 1em;
    gap: 1em;
}

.invited-item {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    grid-gap: 1em;
    gap: 1em;
    height: 40px;
    border: 1px solid gray;
    border-left: 5px solid #fcc75c;
    padding: 0px 15px;
    width: 25rem;
}

.invited-item>div>span {
    font-size: 12px;
    font-style: normal;
}

.invited-form>.form-group>input {

    height: 40px !important;
    border: 0px !important;
    border-bottom: 1px solid gray !important;
    background-color: rgb(250, 250, 250) !important;

}

.panel-buttons {
    flex: 1 0 8rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 1em;

}

.panel-buttons>button {
    flex: 1 0 8rem;
    background-color: #1a67a3 !important;
}

.no-invited-yet {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.no-invited-yet>p {
    font-size: 16px;
}

.panel-preview-email {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 1em;
}

.panel-preview-email>div {
    flex: 1 0 8rem;
    padding: 25px;
}

.panel-preview-email>div>h1 {
    text-align: left;
    width: 100%;
    font-size: 30px;
}

.panel-preview-email>div:first-child {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.promoted-services {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.promoted-services>h1 {
    font-size: 25px;
    width: 100%;
    text-align: left;
}


.input-profile-panel {
    height: 40px !important;
    border: 0px !important;
    border-bottom: 1px solid gray !important;
    background-color: rgb(250, 250, 250) !important;
}

.label-input {
    position: absolute;
    top: 15px;
    left: 15px;
    font-size: 15px !important;
    color: rgb(167, 167, 167) !important;
    pointer-events: none;
    /* Esto evita que el label interfiera con el input */
    transition: all 0.3s ease;
    /* Agregar una transición suave */
}

/* Estilo del placeholder cuando el input está enfocado o tiene valor */
.input-profile-panel:focus+.label-input,
.input-profile-panel:not(:placeholder-shown)+.label-input {
    top: -10px;
    /* Mueve el label hacia arriba cuando el input está enfocado o tiene valor */
    left: 0;

    font-size: 10px !important;
    /* Reduce el tamaño del placeholder cuando el input está enfocado o tiene valor */
    color: #333;
    /* Cambia el color del placeholder cuando el input está enfocado o tiene valor */
}

.input-profile-panel::placeholder {
    font-size: 12px;
}

@media (max-width: 992px) {
    .invited-list {
        margin-top: 15px;
        padding: 0 25px;
        width: 100%;
    }

    .invited-item {
        width: 100%;
    }

    .invited-form {
        width: 100%;
        border-right: 0px solid gray;
    }

    .panel-buttons>button{
        flex: 1 0 10rem;
    }

    .panel-preview-email>div {
        flex: auto;
        padding: 25px;
    }
}