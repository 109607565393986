.main {
  min-height: 200px;
  width: 95%;
  margin: 1em;
  padding: 0px 20px;
  height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
  /* scrollbar-width: none; */
  /* margin-left: 21%; */
}

/* Estilos para navegadores webkit (Chrome, Safari, etc.) */
::-webkit-scrollbar {
  width: 10px;
  /* Ancho de la barra de desplazamiento */
}

::-webkit-scrollbar-thumb {
  background-color: #ccc;
  /* Color del thumb (parte deslizante) */
  border-radius: 5px;
  /* Borde redondeado */
}

::-webkit-scrollbar-track {
  background-color: #f0f0f0;
  /* Color del track (fondo de la barra de desplazamiento) */
  border-radius: 5px;
  /* Borde redondeado */
}

.heading {
  display: flex;
  justify-content: space-between;
  color: rgb(55, 55, 55);
  font-size: 18px;
  font-weight: bold;
  /* border-bottom: 1px solid black; */
}

.heading>i {
  font-style: normal;
  font-size: 15px;
  color: #1a67a3;
  cursor: pointer;
}

.licenceDetails {
  display: flex;
  flex-direction: column;
  padding: 20px 80px;
  align-items: center;
  width: 100%;
}

.inputField {
  height: 40px;
  margin-top: 15px;
  border-color: 1px solid #b4b2b2;
  width: 100%;
}

.vendorInputField {
  height: 40px;
  width: 15vw;
  font-size: 14px;
  border: 1px solid #8080802e;
  margin-right: 1em;
  margin-bottom: 1em;
}

.newInvitationButtonGroup {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
}

.myButton {
  height: 45px;
  width: 180px;
  /* background: rgb(54, 51, 119); */
  background-color: #1a67a3;
  border: none;
  color: white;
  font-weight: bold;
  cursor: pointer;
  margin-top: 15px;
}


.tableStyle {
  border: 1px solid #a9a9b7;
  padding-left: 10px;
}

.vendorProfile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.vendorProfileLabels {
  margin-top: 1em;
  text-align: left;
  font-size: 16px;
  font-weight: bold;
}

.vendorProfilePicture {
  height: 300px;
  width: 300px;
  border-radius: 50%;
  /* border: solid 2px #363377; ticket # 784 requires to change is color to #0018A8 */
  border: solid 2px #0018a8;
  object-fit: cover;
}

.subTitleDetails {
  display: flex;
  width: 100%;
  margin-top: 2em;
}

.tableDetails {
  width: 50%;
}

.rowCardList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0px !important;
  padding: 0px !important;
}

.cardLicenseInfo {
  padding: 10px;
  margin-top: 20px;
  border-radius: 5px;
  border: 1px solid rgb(161, 161, 161);
  border-left: 5px solid #1a67a3;
  height: 28vh;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  background-color: rgb(250, 250, 250);
  width: 30rem;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-wrap: wrap;

}

.cardLicenseInfo:hover {
  box-shadow: 5px 5px 5px rgb(212, 212, 212);
}



.cardLicenseInfo>div>span {
  font-weight: 600;
  color: rgb(114, 114, 114);
  font-size: 13px;
}

.cardLicenseInfo>div>span[data-type="card-text"] {
  font-weight: 500;
  color: rgb(50, 50, 50);
  font-size: 13px !important;
}

.fields {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 5px;
  width: 100%;
}

.fields>span {
  font-size: 15px;
}

.fields>span:first-child {
  font-weight: 600;
  color: rgb(114, 114, 114);
}

.tradeList {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
}

.tradeList>span {
  /* flex: 1 0 1rem; */
  font-size: 12px !important;
  margin: 5px 5px 5px 0px;
  padding: 4px;
  background-color: rgba(26, 103, 163, 0.4);
  border-radius: 10px;
}

.newLicenseForm {
  display: flex;
  flex-direction: column !important;
}

.newLicenseForm>div:first-child {
  display: flex;
  flex-direction: column !important;
  justify-content: center !important;
  width: 100%;
  text-align: center;
}

.heading {
  color: rgb(218, 181, 17);
  font-size: 22px;
  font-weight: bold;
  text-align: center;
}

@media (max-width: 992px) {

  .main {
    height: auto;
    min-height: 200px;
    width: 100%;
    margin: 0px;
    padding: 10px 0px;
    overflow-y: hidden;
    overflow-x: hidden;
  }

  .heading {
    color: rgb(218, 181, 17);
    font-size: 22px;
    font-weight: bold;
    text-align: center;
  }

  .licenceDetails {
    flex-direction: column;
    padding: 5px 5px;
  }

  .inputField {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 10px;

  }

  .subTitleDetails {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .myButton {
    width: 100%;
    height: 45px;
  }

  .tableDetails {
    width: 100%;
  }

  .cardLicenseInfo {
    width: 100%;
  }

  .rowCardList {
    justify-content: center;
  }

  .newInvitationButtonGroup {
    flex-direction: column;
  }

  .vendorInputField {
    width: auto;

  }

}