/* Atom design */

.left-section {
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  border-right: 6px solid rgb(237, 237, 237);
}

.section-item {
  cursor: pointer;
  color: #333;
  transition: background-color 0.2s ease-in-out;
}

.section-item:hover {
  background-color: #f0f0f0;
}

.content {
  min-height: calc(100vh - 56px);
  /* Asegura que el contenido también ocupe todo el alto */
}

p,
i,
h1,
h2,
h3,
h4,
h5,
span,
a {
  font-family: 'Inter', serif;
}

/* Personalizar el scrollbar */
.breadcrumb-container {
  scrollbar-width: thin;
  /* Para navegadores basados en Firefox */
  scrollbar-color: #d5d5d5 #f1f1f1;
  /* Color del thumb y track */
}

/* Para navegadores basados en WebKit (Chrome, Edge, Safari) */
.breadcrumb-container::-webkit-scrollbar {
  width: 6px;
  /* Ancho del scrollbar */
}

.breadcrumb-container::-webkit-scrollbar-thumb {
  background-color: #d5d5d5;
  /* Color del thumb */
  border-radius: 10px;
  /* Bordes redondeados */
}

.breadcrumb-container::-webkit-scrollbar-thumb:hover {
  background-color: #d5d5d5;
  /* Color del thumb al pasar el mouse */
}

.breadcrumb-container::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  /* Color del track */
}


.btn-remove-framework:hover {
  background-color: #ff00005c !important;
}

.btn-more-framework:hover {
  background-color: rgba(176, 179, 2, 0.36) !important;
}

.btn-edit-framework:hover {
  background-color: #1aa35480 !important;
}

.btn-add-framework:hover {
  background-color: #1aa35480 !important;
}

/**modal frameworks*/
.modal-framework {
  background-color: #000000d4;
}

.modal-framework .modal-dialog .modal-content {
  overflow: hidden !important;
}

.modal-framework .modal-dialog .modal-content .modal-body {
  overflow: auto !important;
}
.modal-framework .modal-dialog .modal-content .modal-header {
  padding: 5px 20px;
  border: 0px;
}

/** interactive table frameworks*/
/* input[type="checkbox"] {
  appearance: auto;
  
} */

.container-framework-list {
  padding-left: 20px;
  list-style-type: none
}

.container-framework-list > li {
  padding: 5px 0px;
}

.input-modal-remove{
  width: 50%;
}

.btn-confirm-remove{
  background-color: #1a67a3!important;
  color: white!important;
  border-color: #1a67a3!important;
  width: 10vw;
}
.btn-cancel-remove{
  background-color: #ff0000!important;
  color: white!important;
  border-color: #ff0000!important;
  width: 10vw;
}

@media (max-width: 768px) {
  .container-framework-list {
    padding-left: 20px;
  }

  .input-modal-remove{
    width: 100%;
  }

  .btn-confirm-remove{
    width: 100%;
  }
  .btn-cancel-remove{
    width: 100%;
  }
}

.checkbox-frameworks {
  appearance: none;
  width: 12px;
  height: 12px;
  background-color: #ffffff;
  border: 2px solid #a3a3a3;
  border-radius: 2px;
  cursor: pointer;
  position: relative;
}

.checkbox-frameworks:checked {
  /* background-color: #4caf50; */
  /* border-color: #4caf50; */
  border: 0px;
}

.checkbox-frameworks:checked::after {
  content: '✔';
  color: #4caf50;
  font-size: 14px;
  position: absolute;
  top: 30%;
  left: 30%;
  transform: translate(-50%, -50%);
}

.indeterminate:indeterminate {
  /* background-color: #4caf50; */
  /* border-color: #4caf50; */
  border: 0px;
}

.indeterminate:indeterminate::after {
  content: '\2212'; /* Usar un símbolo personalizado, como un guion largo */
  display: block;
  text-align: center;
  color: rgb(255, 4, 0); /* Cambiar color */
  font-size: 18px;
  font-weight: 600;
  line-height: 16px;
  border: none; /* Opcional: remover bordes si necesario */
  background-color: white; /* Fondo del símbolo */
}



.checkbox-frameworks:focus {
  outline: none;
  border-color: #7c98be;
}

#button-tooltip{
  border: 0px !important;
 
}

#button-tooltip .arrow::before{
  border-right-color: rgb(120, 120, 120)!important;
}

.tooltip-inner{
  font-size: 12px!important;
  background-color: rgb(120, 120, 120)!important;
}
