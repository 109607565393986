.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.75rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: -webkit-calc(var(--bs-gutter-x) * 0.5);
  padding-right: -moz-calc(var(--bs-gutter-x) * 0.5);
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: -webkit-calc(var(--bs-gutter-x) * 0.5);
  padding-left: -moz-calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm,
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md,
  .container-sm,
  .container {
    max-width: 720px;
  }
}
@media (min-width: 1024px) {
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1140px;
  }
}
@media (min-width: 1365px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1320px;
  }
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

*:after,
*:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body {
  min-height: 100vh;
  overflow: unset;
}

body {
  background-color: white;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  position: relative;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
}

.page-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  /* height: 100vh; */
  overflow-x: hidden;
  overflow-y: auto;
}

.page-wrapper .site-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
}

.page-wrapper .site-footer {
  margin-top: auto;
  position: static;
}

.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}

.img-fluid {
  height: auto;
  max-width: 100%;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.mb-0 {
  margin-bottom: 0;
}

.d-block {
  display: block !important;
}

.d-flex {
  display: -webkit-box !important;
  display: -webkit-flex !important;
  display: -moz-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.align-items-center {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.justify-content-center {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.justify-content-between {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.flex-column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

@media (min-width: 1024px) {
  .flex-lg-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -moz-box-orient: horizontal;
    -moz-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}
.land-main-menu {
  /* background-color: #1a67a3 !important; */ /* #363377 was replaced with this color */
  background-color: #1a67a3 !important; /* #1a67a3 was replaced with this color */
}

.land-main-menu .container {
  width: 100%;
  background-color: #1a67a3 !important;
}

@media (max-width: 1199.98px) {
  .land-main-menu .container {
    max-width: 100%;
  }
}
@media (min-width: 1920px) {
  .land-main-menu .container {
    max-width: -webkit-calc(100% - 300px);
    max-width: -moz-calc(100% - 300px);
    max-width: calc(100% - 300px);
  }
}
@media (min-width: 2560px) {
  .land-main-menu .container {
    max-width: -webkit-calc(100% - 400px);
    max-width: -moz-calc(100% - 400px);
    max-width: calc(100% - 400px);
  }
}
.land-main-menu .navbar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  min-height: auto;
  padding: 12px 0;
  background-color: #1a67a3 !important;
}

@media (max-width: 1023.98px) {
  .land-main-menu .navbar {
    padding: 15px 0;
  }
}
@media (min-width: 3400px) {
  .land-main-menu .navbar {
    padding: 30px 0;
  }
}
.land-main-menu .navbar .navbar-brand {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: 0;
  min-height: auto;
  padding: 0;
  text-decoration: none;
}

.land-main-menu .navbar .navbar-brand span {
  line-height: 1;
  display: inline-block;
}

.land-main-menu .navbar .navbar-brand span:first-of-type {
  max-width: 80px;
}

@media (min-width: 3400px) {
  .land-main-menu .navbar .navbar-brand span:first-of-type {
    max-width: 150px;
  }
}
.land-main-menu .navbar .navbar-brand span:not(:last-child) {
  margin-right: 10px;
}

.land-main-menu .navbar .navbar-brand span:last-of-type {
  color: white;
  font-size: 24px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  line-height: 1.5;
}

@media (min-width: 3400px) {
  .land-main-menu .navbar .navbar-brand span:last-of-type {
    font-size: 36px;
  }
}
.land-main-menu .navbar .navbar-brand:hover,
.land-main-menu .navbar .navbar-brand:focus {
  text-decoration: none;
}

.land-main-menu .navbar .navbar-text {
  color: #fcc75c /* yellow*/;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
}

@media (max-width: 1199.98px) {
  .land-main-menu .navbar .navbar-text {
    font-size: 16px;
  }
}
@media only screen and (max-width: 600px),
  (width: 768px) and (height: 1024px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: portrait) {
  .land-main-menu .navbar .navbar-text {
    margin-bottom: 30px;
  }
}
@media (max-width: 767.98px) {
  .land-main-menu .navbar .navbar-text {
    font-size: 14px;
    margin-bottom: 0;
  }
}
@media (min-width: 414px) and (max-width: 599px) {
  .land-main-menu .navbar .navbar-text {
    margin-bottom: 30px;
  }
}
@media (min-width: 3400px) {
  .land-main-menu .navbar .navbar-text {
    font-size: 32px;
  }
}
.land-main-menu .navbar .navbar-buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
}

.navbar-build-menu{
  width: 25%;
  justify-content: space-around;
}
.navbar-build-menu>a{
  color: white;
}

@media (max-width: 767.98px) {
  .land-main-menu .navbar .navbar-buttons {
    width: 100%;
  }

  .navbar-build-menu{
    width: 100%;
    text-align: center;
    flex-direction: column;
    margin-bottom: 15px;
    margin-top: 15px;
  }

  
}
.land-main-menu .navbar .navbar-buttons .navbar-buttons-link {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #9e9e9e /* yellow*/;
  -webkit-border-radius: 5px;
  border-radius: 35px;
  color: rgb(61, 61, 61) /* yellow*/;
  font-weight: 500;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;  
  height: 30px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 1;
  outline: none;
  padding: 2px 5px;
  text-decoration: none;
  /* text-transform: uppercase; */
  -webkit-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  width: 150px;
}

@media (max-width: 1364.98px) {
  .land-main-menu .navbar .navbar-buttons .navbar-buttons-link {
    font-size: 16px;
    width: 160px;
  }
}
@media (max-width: 1023.98px) {
  .land-main-menu .navbar .navbar-buttons .navbar-buttons-link {
    width: 200px;
  }
}
@media (max-width: 767.98px) {
  .land-main-menu .navbar .navbar-buttons .navbar-buttons-link {
    width: auto;
  }
}
@media (max-width: 575.98px) {
  .land-main-menu .navbar .navbar-buttons .navbar-buttons-link {
    font-size: 14px;
  }
}
@media (min-width: 3400px) {
  .land-main-menu .navbar .navbar-buttons .navbar-buttons-link {
    font-size: 28px;
    height: 80px;
    width: 300px;
  }
}
.land-main-menu .navbar .navbar-buttons .navbar-buttons-link:hover
 {
  background-color: #ffffff /* yellow*/;
  color: #1a67a3;
  outline: none;
}

.land-footer {
  background-color: #1a67a3;
  padding: 12px 0;
}

@media (max-width: 1023.98px) {
  .land-footer {
    padding: 15px 0;
  }
}
@media (min-width: 3400px) {
  .land-footer {
    padding: 25px 0;
  }
}
.land-footer .container {
  width: 100%;
}

@media (max-width: 1199.98px) {
  .land-footer .container {
    max-width: 100%;
  }
}
@media (min-width: 1920px) {
  .land-footer .container {
    max-width: -webkit-calc(100% - 300px);
    max-width: -moz-calc(100% - 300px);
    max-width: calc(100% - 300px);
  }
}
@media (min-width: 2560px) {
  .land-footer .container {
    max-width: -webkit-calc(100% - 400px);
    max-width: -moz-calc(100% - 400px);
    max-width: calc(100% - 400px);
  }
}
.land-footer .land-footer-buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -moz-box-orient: horizontal;
  -moz-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media (max-width: 767.98px) {
  .land-footer .land-footer-buttons {
    width: 100%;
  }
}
.land-footer .land-footer-buttons .land-footer-buttons-link {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
  border: 2px solid #fcc75c /* yellow*/;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  color: #fcc75c /* yellow*/;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  height: 46px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 1;
  outline: none;
  padding: 5px 15px;
  text-decoration: none;
  text-transform: capitalize;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  width: 160px;
}

@media (max-width: 767.98px) {
  .land-footer .land-footer-buttons .land-footer-buttons-link {
    width: auto;
  }
}
@media (max-width: 374.98px) {
  .land-footer .land-footer-buttons .land-footer-buttons-link {
    font-size: 14px;
    padding: 5px 10px;
  }
}
@media (min-width: 3400px) {
  .land-footer .land-footer-buttons .land-footer-buttons-link {
    height: 80px;
    font-size: 28px;
    width: 260px;
  }
}
.land-footer .land-footer-buttons .land-footer-buttons-link:hover,
.land-footer .land-footer-buttons .land-footer-buttons-link:focus {
  background-color: #fcc75c /* yellow*/;
  color: #1a67a3;
  outline: none;
}

.land-footer .land-footer-social {
  margin-bottom: 0;
  margin-top: 0;
}

@media (max-width: 1023.98px) {
  .land-footer .land-footer-social {
    margin: 12px 0;
  }
}
@media (max-width: 767.98px) {
  .land-footer .land-footer-social {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
@media (min-width: 3400px) {
  .land-footer .land-footer-social {
    margin: 25px 0;
  }
}
.land-footer .land-footer-social .land-footer-app-store {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  line-height: 1;
  margin-right: 10px;
}

@media (max-width: 767.98px) {
  .land-footer .land-footer-social .land-footer-app-store {
    margin-bottom: 10px;
    margin-right: 0;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
  }
}
.land-footer
  .land-footer-social
  .land-footer-app-store
  .land-footer-app-store-link {
  display: block;
  height: 40px;
  line-height: 0;
  width: 135px;
}

.land-footer
  .land-footer-social
  .land-footer-app-store
  .land-footer-app-store-link:first-of-type {
  margin-right: 10px;
  width: 120px;
}

.land-footer .land-footer-social .land-footer-social-item:not(:last-child) {
  margin-right: 10px;
}

.land-footer
  .land-footer-social
  .land-footer-social-item
  .land-footer-social-link {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #201e3e;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  color: #b0b3b8;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 17px;
  height: 30px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 1;
  outline: none;
  text-decoration: none;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  width: 30px;
}

@media (min-width: 3400px) {
  .land-footer
    .land-footer-social
    .land-footer-social-item
    .land-footer-social-link {
    height: 60px;
    font-size: 45px;
    width: 60px;
  }
}
.land-footer
  .land-footer-social
  .land-footer-social-item
  .land-footer-social-link:hover,
.land-footer
  .land-footer-social
  .land-footer-social-item
  .land-footer-social-link:focus {
  color: white;
  outline: none;
}

.land-footer .copy-right {
  color: white;
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
  padding: 0;
}

@media (min-width: 3400px) {
  .land-footer .copy-right {
    font-size: 24px;
  }
}
.land-footer .copy-right a {
  color: white;
  text-decoration: none;
  text-underline-position: under;
}

.land-footer .copy-right a:hover,
.land-footer .copy-right a:focus {
  text-decoration: underline;
}

.footer-regular {
  background-color: #1a67a3;
  padding: 12px 0;
}

@media (max-width: 1023.98px) {
  .footer-regular {
    padding: 15px 0;
  }
}
@media (min-width: 3400px) {
  .footer-regular {
    padding: 25px 0;
  }
}
.footer-regular .footer-regular-buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
}

@media (max-width: 767.98px) {
  .footer-regular .footer-regular-buttons {
    width: 100%;
  }
}
.footer-regular .footer-regular-buttons .footer-regular-buttons-link {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
  border: 2px solid #fcc75c /* yellow*/;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  color: #fcc75c /* yellow*/;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  height: 46px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 1;
  outline: none;
  padding: 5px 15px;
  text-decoration: none;
  text-transform: capitalize;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  width: 160px;
}

@media (max-width: 767.98px) {
  .footer-regular .footer-regular-buttons .footer-regular-buttons-link {
    width: auto;
  }
}
@media (max-width: 374.98px) {
  .footer-regular .footer-regular-buttons .footer-regular-buttons-link {
    font-size: 14px;
    padding: 5px 10px;
  }
}
@media (min-width: 3400px) {
  .footer-regular .footer-regular-buttons .footer-regular-buttons-link {
    height: 80px;
    font-size: 28px;
    width: 260px;
  }
}
.footer-regular .footer-regular-buttons .footer-regular-buttons-link:hover,
.footer-regular .footer-regular-buttons .footer-regular-buttons-link:focus {
  background-color: #fcc75c /* yellow*/;
  color: #1a67a3;
  outline: none;
}

.footer-regular .footer-regular-social {
  margin-bottom: 0;
}

@media (max-width: 1023.98px) {
  .footer-regular .footer-regular-social {
    margin: 12px 0;
  }
}
@media (min-width: 3400px) {
  .footer-regular .footer-regular-social {
    margin: 25px 0;
  }
}
.footer-regular
  .footer-regular-social
  .footer-regular-social-item:not(:last-child) {
  margin-right: 10px;
}

.footer-regular
  .footer-regular-social
  .footer-regular-social-item
  .footer-regular-social-link {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #201e3e;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  color: #b0b3b8;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 17px;
  height: 30px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 1;
  outline: none;
  text-decoration: none;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  width: 30px;
}

@media (min-width: 3400px) {
  .footer-regular
    .footer-regular-social
    .footer-regular-social-item
    .footer-regular-social-link {
    height: 60px;
    font-size: 45px;
    width: 60px;
  }
}
.footer-regular
  .footer-regular-social
  .footer-regular-social-item
  .footer-regular-social-link:hover,
.footer-regular
  .footer-regular-social
  .footer-regular-social-item
  .footer-regular-social-link:focus {
  color: white;
  outline: none;
}

.footer-regular .copy-right {
  color: white;
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
  padding: 0;
}

@media (min-width: 3400px) {
  .footer-regular .copy-right {
    font-size: 24px;
  }
}
.footer-regular .copy-right a {
  color: white;
  text-decoration: none;
  text-underline-position: under;
}

.footer-regular .copy-right a:hover,
.footer-regular .copy-right a:focus {
  text-decoration: underline;
}

.land-hero {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  position: relative;
}

.land-hero .container {
  width: 100%;
}

@media (max-width: 1199.98px) {
  .land-hero .container {
    max-width: 100%;
  }
}
@media (min-width: 1920px) {
  .land-hero .container {
    max-width: -webkit-calc(100% - 300px);
    max-width: -moz-calc(100% - 300px);
    max-width: calc(100% - 300px);
  }
}
@media (min-width: 2560px) {
  .land-hero .container {
    max-width: -webkit-calc(100% - 400px);
    max-width: -moz-calc(100% - 400px);
    max-width: calc(100% - 400px);
  }
}
.land-hero h4 {
  color: #1a67a3;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  line-height: 1.5;
  padding-top: 20px;
}

@media (max-width: 1023.98px) {
  .land-hero h4 {
    margin-bottom: 16px;
  }
}
@media (min-width: 2561px) {
  .land-hero h4 {
    font-size: 28px;
  }
}
.land-hero .container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.land-hero .land-hero-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  min-height: -webkit-calc(100vh - 140px);
  min-height: -moz-calc(100vh - 140px);
  min-height: calc(100vh - 140px);
  margin-top: auto;
  overflow: hidden;
  position: relative;
}

@media (max-width: 1023.98px) {
  .land-hero .land-hero-container {
    min-height: auto;
  }
}
.land-hero .land-hero-container .land-hero-image {
  bottom: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  right: 100px;
  top: 0;
}

@media (max-width: 1023.98px) {
  .land-hero .land-hero-container .land-hero-image {
    display: none;
  }
}
@media (max-width: 1890px) {
  .land-hero .land-hero-container .land-hero-image {
    right: 0;
  }
}
@media only screen and (max-width: 900px),
  (width: 1024px) and (height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: portrait) {
  .land-hero .land-hero-container .land-hero-image {
    display: none;
    position: static;
  }
}
@media (min-width: 1365px) and (max-width: 1399px) {
  .land-hero .land-hero-container .land-hero-image {
    right: 80px;
  }
}
@media only screen and (height: 1024px) and (width: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: landscape) {
  .land-hero .land-hero-container .land-hero-image {
    right: 0;
  }
}
.land-hero .land-hero-container .land-hero-image img {
  max-height: 100%;
}

.land-hero .land-hero-container .land-hero-text {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -moz-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-bottom: 20px;
  padding-left: 80px;
  padding-top: 20px;
  position: relative;
  z-index: 1;
}

@media (max-width: 1199.98px) {
  .land-hero .land-hero-container .land-hero-text {
    padding-left: 0;
  }
}
@media only screen and (max-width: 900px),
  (width: 1024px) and (height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: portrait) {
  .land-hero .land-hero-container .land-hero-text {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}
@media only screen and (height: 1024px) and (width: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: landscape) {
  .land-hero .land-hero-container .land-hero-text {
    padding-left: 0;
  }
}
@media only screen and (max-width: 600px),
  (width: 768px) and (height: 1024px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: portrait) {
  .land-hero .land-hero-container .land-hero-text {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}
@media (max-width: 767.98px) {
  .land-hero .land-hero-container .land-hero-text {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    width: 100%;
  }
}
@media (min-width: 414px) and (max-width: 599px) {
  .land-hero .land-hero-container .land-hero-text {
    padding: 60px 0;
  }
}
@media (min-width: 1260px) and (max-width: 1364.98px) {
  .land-hero .land-hero-container .land-hero-text {
    padding-left: 0;
  }
}
@media (min-width: 1600px) and (max-width: 1680px) {
  .land-hero .land-hero-container .land-hero-text {
    padding-left: 0;
  }
}
@media (min-width: 2560px) {
  .land-hero .land-hero-container .land-hero-text {
    padding-left: 200px;
  }
}
.land-hero .land-hero-container .land-hero-text h1 {
  color: #1a67a3;
  font-size: 85px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 30px;
  max-width: 70%;
}

@media (max-width: 1199.98px) {
  .land-hero .land-hero-container .land-hero-text h1 {
    font-size: 55px;
    max-width: 80%;
  }
}
@media (max-width: 1023.98px) {
  .land-hero .land-hero-container .land-hero-text h1 {
    font-size: 46px;
    margin-bottom: 24px;
    max-width: 100%;
    text-align: center;
  }
}
@media only screen and (max-width: 900px),
  (width: 1024px) and (height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: portrait) {
  .land-hero .land-hero-container .land-hero-text h1 {
    font-size: 83px;
    margin-bottom: 100px;
    text-align: center;
    max-width: 100%;
  }
}
@media only screen and (max-width: 600px),
  (width: 768px) and (height: 1024px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: portrait) {
  .land-hero .land-hero-container .land-hero-text h1 {
    font-size: 60px;
    margin-bottom: 50px;
  }
}
@media (max-width: 767.98px) {
  .land-hero .land-hero-container .land-hero-text h1 {
    margin-bottom: 24px;
    font-size: 36px;
  }
}
@media (max-width: 575.98px) {
  .land-hero .land-hero-container .land-hero-text h1 {
    font-size: 28px;
    margin-bottom: 15px;
  }
}
@media (min-width: 414px) and (max-width: 599px) {
  .land-hero .land-hero-container .land-hero-text h1 {
    font-size: 32px;
  }
}
@media (min-width: 1260px) and (max-width: 1364.98px) {
  .land-hero .land-hero-container .land-hero-text h1 {
    font-size: 60px;
  }
}
@media (min-width: 1365px) and (max-width: 1399px) {
  .land-hero .land-hero-container .land-hero-text h1 {
    font-size: 65px;
    max-width: 82%;
  }
}
@media only screen and (height: 1024px) and (width: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: landscape) {
  .land-hero .land-hero-container .land-hero-text h1 {
    font-size: 80px;
  }
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .land-hero .land-hero-container .land-hero-text h1 {
    font-size: 70px;
  }
}
@media (min-width: 1600px) and (max-width: 1680px) {
  .land-hero .land-hero-container .land-hero-text h1 {
    font-size: 75px;
  }
}
@media (min-width: 2560px) {
  .land-hero .land-hero-container .land-hero-text h1 {
    font-size: 100px;
    max-width: 80%;
  }
}
@media (min-width: 3400px) {
  .land-hero .land-hero-container .land-hero-text h1 {
    font-size: 150px;
    max-width: 80%;
  }
}
.land-hero .land-hero-container .land-hero-text h2 {
  color: #1a67a3;
  font-size: 46px;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  line-height: 1.2;
  margin-bottom: 30px;
  text-align: center;
}

@media (max-width: 1199.98px) {
  .land-hero .land-hero-container .land-hero-text h2 {
    font-size: 40px;
  }
}
@media only screen and (max-width: 900px),
  (width: 1024px) and (height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: portrait) {
  .land-hero .land-hero-container .land-hero-text h2 {
    font-size: 65px;
    margin-bottom: 60px;
  }
}
@media only screen and (max-width: 600px),
  (width: 768px) and (height: 1024px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: portrait) {
  .land-hero .land-hero-container .land-hero-text h2 {
    font-size: 46px;
    margin-bottom: 30px;
  }
}
@media (max-width: 1023.98px) {
  .land-hero .land-hero-container .land-hero-text h2 {
    font-size: 32px;
    margin-bottom: 24px;
    max-width: 100%;
    text-align: center;
  }
}
@media (max-width: 575.98px) {
  .land-hero .land-hero-container .land-hero-text h2 {
    font-size: 24px;
    margin-bottom: 15px;
  }
}
@media (min-width: 414px) and (max-width: 599px) {
  .land-hero .land-hero-container .land-hero-text h2 {
    font-size: 30px;
  }
}
@media (min-width: 2560px) {
  .land-hero .land-hero-container .land-hero-text h2 {
    font-size: 66px;
  }
}
@media (min-width: 3400px) {
  .land-hero .land-hero-container .land-hero-text h2 {
    font-size: 100px;
  }
}
.land-hero .land-hero-container .land-hero-text h3 {
  color: #c00000;
  font-size: 35px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 30px;
  text-align: center;
}

@media (max-width: 1199.98px) {
  .land-hero .land-hero-container .land-hero-text h3 {
    font-size: 24px;
  }
}
@media only screen and (max-width: 900px),
  (width: 1024px) and (height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: portrait) {
  .land-hero .land-hero-container .land-hero-text h3 {
    font-size: 40px;
    margin-bottom: 50px;
  }
}
@media only screen and (max-width: 600px),
  (width: 768px) and (height: 1024px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: portrait) {
  .land-hero .land-hero-container .land-hero-text h3 {
    font-size: 35px;
    margin-bottom: 20px;
  }
}
@media (max-width: 1023.98px) {
  .land-hero .land-hero-container .land-hero-text h3 {
    font-size: 20px;
  }
}
@media (max-width: 575.98px) {
  .land-hero .land-hero-container .land-hero-text h3 {
    font-size: 18px;
    margin-bottom: 15px;
  }
}
@media (min-width: 1260px) and (max-width: 1364.98px) {
  .land-hero .land-hero-container .land-hero-text h3 {
    font-size: 26px;
  }
}
@media (min-width: 1365px) and (max-width: 1599px) {
  .land-hero .land-hero-container .land-hero-text h3 {
    font-size: 30px;
  }
}
@media (min-width: 1600px) and (max-width: 1680px) {
  .land-hero .land-hero-container .land-hero-text h3 {
    font-size: 30px;
  }
}
@media (min-width: 2560px) {
  .land-hero .land-hero-container .land-hero-text h3 {
    font-size: 46px;
  }
}
@media (min-width: 3400px) {
  .land-hero .land-hero-container .land-hero-text h3 {
    font-size: 52px;
  }
}
.land-hero .land-hero-container .land-hero-text .land-hero-join {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: 30px;
  margin-top: 50px;
  width: 600px;
}

@media (max-width: 1599.98px) {
  .land-hero .land-hero-container .land-hero-text .land-hero-join {
    margin-top: 0;
  }
}
@media (max-width: 1199.98px) {
  .land-hero .land-hero-container .land-hero-text .land-hero-join {
    margin-top: 0;
    width: 442px;
  }
}
@media (max-width: 1023.98px) {
  .land-hero .land-hero-container .land-hero-text .land-hero-join {
    margin-top: 0;
    width: 100%;
  }
}
@media (max-width: 575.98px) {
  .land-hero .land-hero-container .land-hero-text .land-hero-join {
    margin-bottom: 15px;
  }
}
@media only screen and (max-width: 900px),
  (width: 1024px) and (height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: portrait) {
  .land-hero .land-hero-container .land-hero-text .land-hero-join {
    width: 100%;
  }
}
@media (min-width: 1365px) and (max-width: 1399.98px) {
  .land-hero .land-hero-container .land-hero-text .land-hero-join {
    margin-top: 20px;
    width: 552px;
  }
}
@media (min-width: 3400px) {
  .land-hero .land-hero-container .land-hero-text .land-hero-join {
    width: 1288px;
  }
}
.land-hero .land-hero-container .land-hero-text .land-hero-join p {
  color: #1a67a3;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  line-height: 1.25;
  margin-bottom: 10px;
  text-align: center;
}

@media only screen and (max-width: 900px),
  (width: 1024px) and (height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: portrait) {
  .land-hero .land-hero-container .land-hero-text .land-hero-join p {
    font-size: 30px;
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 600px),
  (width: 768px) and (height: 1024px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: portrait) {
  .land-hero .land-hero-container .land-hero-text .land-hero-join p {
    font-size: 20px;
    margin-bottom: 16px;
  }
}
@media (max-width: 575.98px) {
  .land-hero .land-hero-container .land-hero-text .land-hero-join p {
    font-size: 13px;
  }
}
@media (min-width: 2560px) {
  .land-hero .land-hero-container .land-hero-text .land-hero-join p {
    font-size: 24px;
    margin-bottom: 24px;
  }
}
@media (min-width: 3400px) {
  .land-hero .land-hero-container .land-hero-text .land-hero-join p {
    font-size: 42px;
  }
}
.land-hero .land-hero-container .land-hero-text .land-hero-join p a {
  color: #c00000;
  font-style: italic;
}

.land-hero
  .land-hero-container
  .land-hero-text
  .land-hero-join
  .land-hero-join-link {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fcc75c /* yellow*/;
  border: 2px solid #c00000;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  color: #c00000;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  height: 46px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  letter-spacing: 1px;
  line-height: 1;
  margin-bottom: 10px;
  outline: none;
  padding: 5px 15px;
  text-decoration: none;
  text-transform: uppercase;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  width: 200px;
}

@media only screen and (max-width: 900px),
  (width: 1024px) and (height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: portrait) {
  .land-hero
    .land-hero-container
    .land-hero-text
    .land-hero-join
    .land-hero-join-link {
    font-size: 18px;
    height: 60px;
    margin-bottom: 30px;
    width: 300px;
  }
}
@media only screen and (max-width: 600px),
  (width: 768px) and (height: 1024px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: portrait) {
  .land-hero
    .land-hero-container
    .land-hero-text
    .land-hero-join
    .land-hero-join-link {
    font-size: 16px;
    height: 46px;
    margin-bottom: 20px;
    width: 200px;
  }
}
@media (max-width: 575.98px) {
  .land-hero
    .land-hero-container
    .land-hero-text
    .land-hero-join
    .land-hero-join-link {
    margin-bottom: 10px;
  }
}
@media (min-width: 2560px) {
  .land-hero
    .land-hero-container
    .land-hero-text
    .land-hero-join
    .land-hero-join-link {
    margin-bottom: 24px;
  }
}
@media (min-width: 3400px) {
  .land-hero
    .land-hero-container
    .land-hero-text
    .land-hero-join
    .land-hero-join-link {
    height: 80px;
    font-size: 20px;
    width: 400px;
  }
}
.land-hero
  .land-hero-container
  .land-hero-text
  .land-hero-join
  .land-hero-join-link:hover,
.land-hero
  .land-hero-container
  .land-hero-text
  .land-hero-join
  .land-hero-join-link:focus {
  background-color: #fcc75c /* yellow*/;
  color: #1a67a3;
  outline: none;
}

.land-hero .land-hero-container .land-hero-text .create-profile {
  color: #1a67a3;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  padding-bottom: 20px;
  text-align: center;
  width: 600px;
}

@media (max-width: 1199.98px) {
  .land-hero .land-hero-container .land-hero-text .create-profile {
    width: 442px;
  }
}
@media (max-width: 1023.98px) {
  .land-hero .land-hero-container .land-hero-text .create-profile {
    width: 100%;
  }
}
@media (min-width: 2560px) {
  .land-hero .land-hero-container .land-hero-text .create-profile {
    font-size: 24px;
  }
}
@media (min-width: 3400px) {
  .land-hero .land-hero-container .land-hero-text .create-profile {
    font-size: 42px;
    width: 1288px;
  }
}
.land-hero .land-hero-container .land-hero-text .create-profile a {
  color: #c00000;
  font-style: italic;
}

.land-hero .land-hero-help-container {
  margin-bottom: 15px;
  width: 600px;
}

@media (max-width: 1199.98px) {
  .land-hero .land-hero-help-container {
    margin-top: 0;
    width: 442px;
  }
}
@media (max-width: 1023.98px) {
  .land-hero .land-hero-help-container {
    margin-top: 0;
    width: 100%;
  }
}
@media only screen and (max-width: 900px),
  (width: 1024px) and (height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: portrait) {
  .land-hero .land-hero-help-container {
    width: 100%;
  }
}
@media (min-width: 1365px) and (max-width: 1399.98px) {
  .land-hero .land-hero-help-container {
    width: 552px;
  }
}
@media (min-width: 3400px) {
  .land-hero .land-hero-help-container {
    width: 1288px;
  }
}
.land-hero .land-hero-help {
  bottom: 30px;
  position: absolute;
  right: 30px;
  z-index: 1;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
  border: 2px solid #1a67a3;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  color: #1a67a3;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  height: 46px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 1;
  outline: none;
  padding: 5px 15px;
  text-decoration: none;
  text-transform: capitalize;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  width: 160px;
}

@media (max-width: 1599px) {
  .land-hero .land-hero-help {
    position: static;
  }
}
@media (max-width: 767.98px) {
  .land-hero .land-hero-help {
    width: auto;
  }
}
@media (max-width: 374.98px) {
  .land-hero .land-hero-help {
    font-size: 14px;
    padding: 5px 10px;
  }
}
@media (min-width: 3400px) {
  .land-hero .land-hero-help {
    height: 80px;
    font-size: 28px;
    width: 260px;
  }
}
.land-hero .land-hero-help:hover,
.land-hero .land-hero-help:focus {
  background-color: #1a67a3;
  color: white;
  outline: none;
}
