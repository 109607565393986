.workflow-container {
    height: 100vh;
    position: relative;

}

.workflow-container.children {
    height: auto;
    position: relative;

}

.workflow-body {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    min-height: 80vh;
    margin-bottom: 15px;
    padding: 0px;
}

.workflow-body.children {
    padding: 0px;
    min-height: auto;
}

.workflow-footer {
    /* position: absolute; */
    bottom: 0px;
    width: 100%;
}

.workflow-container .small-popup .modal-card {
    width: 900px !important;
    height: 500px !important;
    max-width: 95%;
    text-align: center;
    /* transition: width 2s ease-in-out; */
    transition: height .5s ease-in-out, width .5s ease-in-out;
}

.workflow-container .small-popup .modal-card.workflow-information-expanded {
    width: 550px !important;
    height: 600px !important;
}

.workflow-container .small-popup .modal-card.modal-entrance {
    width: 550px !important;
    height: 600px !important;
}



.workflow-container .small-popup .modal-card.workflow-information-expanded .modal-card-body {
    display: flex;
    flex-direction: column;
    justify-content: start !important;
    align-items: start !important;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
}

.workflow-container .small-popup .modal-card.workflow-information-expanded .modal-card-body.cont-tree {
    overflow-x: hidden;
    overflow-y: auto;
}

/* .workflow-container .small-popup .modal-card.workflow-information-expanded.read-only .modal-card-body {  
    overflow-x: hidden;
    overflow-y: hidden;
} */

.workflow-container .small-popup .modal-card .modal-card-body {
    display: flex;
    flex-direction: row;
    justify-content: center !important;
    align-items: center !important;
}

.workflow-container .small-popup .modal-card .modal-card-body.entrance {

    flex-direction: column;
}

.workflow-container .small-popup .modal-card .modal-card-body .div-close-button {
    display: flex;
    flex-direction: row;
    justify-content: end;
    width: 100%;
}

.workflow-container .small-popup .modal-card .modal-card-body .div-close-button>button {
    background-color: white;
    border: 0px;
    margin: 0px;
    padding: 0px;
    font-size: 16px;
    font-weight: 800;
    padding: 0px 15px;
    color: #fcc75c;
}

.workflow-container .small-popup .modal-card .modal-card-body>form {
    width: 100%;
    height: -webkit-fill-available;
    height: -moz-available;
    height: fill-available;

}

.workflow-container .small-popup .modal-card .modal-card-body>form>h3 {
    font-size: 28px;
    font-family: "Montserrat";
    font-weight: 600;
    margin-bottom: 15px;
}

.workflow-container .small-popup .modal-card .modal-card-body>form>p {
    font-size: 16px;
    font-family: "Montserrat";
    margin-bottom: 15px;
}

.workflow-description {
    width: 75%;
}


.workflow-description>h3 {
    font-size: 28px;
    font-family: "Montserrat";
    font-weight: 600;
}

.workflow-description>p {
    font-size: 18px;
    font-family: "Montserrat";
    font-weight: 400;
}

.workflow-description .carousel .carousel-indicators {
    bottom: -50px !important;
}


.workflow-description .carousel .carousel-indicators>li {
    background-color: #1a67a3 !important;
}

.workflow-description-div {
    width: 75%;
}

.workflow-description-div {
    width: 100% !important;
}

.workflow-description-div>h3 {
    font-size: 28px;
    font-family: "Montserrat";
    font-weight: 600;
}

.workflow-description-div>p {
    font-size: 18px;
    font-family: "Montserrat";
    font-weight: 400;
}

.workflow-description-div .carousel .carousel-indicators {
    bottom: -25px !important;
}

.workflow-description-div .carousel .carousel-indicators>li {
    background-color: #1a67a3 !important;
}


.carousel-item.active {
    height: 36rem !important;
}


.carousel-workflow .carousel-caption {
    position: relative;
    bottom: 0px;
    color: black;
    text-align: center;
    right: 0%;
    bottom: 0%;
    left: 0%;
    padding-top: 0px;
    padding-bottom: 0px;
}

.carousel-workflow .carousel-caption>h3 {
    font-size: 28px;
    font-family: "Montserrat";
    font-weight: 600;
}

.carousel-workflow .carousel-caption>p {
    font-size: 16px;
    font-family: "Montserrat";
    font-weight: 400;
}

.btn-start-workflow {
    background-color: #1a67a3 !important;
    border: 0px;
    border-color: #1a67a3 !important;
    color: white;
    font-weight: 600;
    border-radius: 0px !important;
    font-size: 18px !important;
    font-family: "Montserrat";
    padding: 6px;
}

.btn-start-workflow-div {
    background-color: #1a67a3 !important;
    border: 0px;
    border-color: #1a67a3 !important;
    color: white;
    font-weight: 600;
    border-radius: 0px !important;
    font-size: 18px !important;
    font-family: "Montserrat";
    padding: 6px;
    width: 200px;
    margin-top: 20px;
}


.phase-1 {
    position: absolute;
    padding: 0px 30px;
    left: 100%;
    transition: transform .5s ease-in-out;
    overflow-x: auto;
}

.phase-1.active {
    transform: translateX(-100%);
}

.phase-1.inactive {
    display: none;
}

.phase-1.inactive-left {
    transform: translateX(-200%);
    display: block;
}

.phase-2 {
    position: absolute;
    padding: 0px 30px;
    left: 100%;
    transition: transform .5s ease-in-out;
    overflow-x: auto;
}

.phase-2.active {
    transform: translateX(-100%);
}

.phase-2.inactive {

    display: none;
}

.phase-2.inactive-left {
    transform: translateX(-200%);
    display: block;
}

.phase-3 {
    position: absolute;
    padding: 0px 30px;
    left: 100%;
    transition: transform .5s ease-in-out;
    overflow-x: auto;
}

.phase-3.active {
    transform: translateX(-100%);
}

.phase-3.inactive {
    display: none;
}

.phase-3.inactive-left {
    transform: translateX(-200%);
    display: block;
}

.phase-3>h3 {
    font-size: 20px !important;
    text-align: center;
}

.phase-3>p {
    font-size: 14px !important;
    text-align: center;
    margin: 0px !important;
}

.phase-3 .phase-3-span {
    font-size: 13px !important;
    text-align: center;
    margin: 0px !important;
}

.workflow-details {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    /* padding: 50px; */
    width: 100%;
    overflow: auto;
    position: relative;
}

.workflow-details-block {
    width: 100%;
    padding: 0px 50px;
    display: flex;
    flex-direction: row;
}

.workflow-details-description {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 15px 10px;
    border: 1px solid #ececec;
    border-left: 4px solid #1a67a3;
    background-color: #f5f5f5;
    margin: 10px 0px;
    box-shadow: 5px 5px 10px rgb(231, 231, 231);
    width: 50%;
    margin-right: 5px;
}

.workflow-details-description>span {
    font-family: "Montserrat";
}

.workflow-details-phase-1 {
    display: flex;
    flex-direction: column;
    padding: 15px 10px;
    border: 1px solid #ececec;
    border-left: 4px solid #1a67a3;
    background-color: #f5f5f5;
    margin: 10px 0px;
    box-shadow: 5px 5px 10px rgb(231, 231, 231);

}

.workflow-details-phase-1>p {
    font-family: "Montserrat";
    padding-right: 40px;
}

.workflow-details-phase-2 {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 15px 10px;
    border: 1px solid #ececec;
    border-left: 4px solid #1a67a3;
    background-color: #f5f5f5;
    margin: 10px 0px;
    box-shadow: 5px 5px 10px rgb(231, 231, 231);
    width: 50%;
    margin-left: 5px;
}

.workflow-details-phase-2>p {
    font-family: "Montserrat";
    padding-right: 40px;
    margin: 0px;
}

.learn-more-block {
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
}

.learn-more-block>span {
    font-size: 14px;
    font-family: "Montserrat";
    padding: 5px 0px;
    color: #1a67a3;
    cursor: pointer;

}

.title-panel-phase {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.title-phase {
    font-family: "Montserrat";
    padding: 5px 0px;
    margin: 0px;
}

.title-modal-step {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid black;
    margin-bottom: 25px;
}

.title-phase-14 {
    font-family: "Montserrat";
    padding: 5px 0px;
    margin: 0px;
    font-size: 18px;
}

.edit-phase-details {
    font-family: "Montserrat";
    padding: 5px 0px;
    margin: 0px;
    font-size: 18px;
}

.edit-phase {
    font-family: "Montserrat";
    font-size: 14px;
    /* padding: 5px 8px; */
    margin: 0px;
    /* border: 1px solid #1a67a3;
    background-color: #1a67a3; */
    border-radius: 5px;
    color: #1a67a3;
    cursor: pointer;
}

.edit-phase:hover {
    text-decoration: underline;
}

.workflow-controls {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    width: 100%;
    margin-top: 10px;
    padding: 0px 50px;
}

.workflow-controls>button {
    width: 150px;
    padding: 5px;
    margin: 0px 5px;
    color: white;
    font-size: 14px;
    font-weight: 600;
    border-color: #1D6F42;
    border: 0px;
    font-family: "Montserrat";
}

.workflow-controls>button:first-child {
    background-color: #8e8e8e
}

.workflow-controls>button:last-child {
    background-color: #1a67a3;
}

.workflow-controls>button {
    background-color: #1D6F42;
    ;
}

.workflow-controls>button:disabled {
    background-color: #96d5b3 !important;
    ;
}

.btn-workflow-download {
    width: 250px !important;
    font-size: 14px !important;
    background-color: #1D6F42 !important;
}

.item-information {
    position: fixed;
    bottom: 0px;
    left: 0px;
    background-color: rgb(238, 238, 238);
    width: 100%;
    height: 0px;

    transition: height .5s ease-in-out;
}

.item-information.active {
    border-top: 4px solid #1a67a3;
    height: 150px;
}

.error-input {
    border-color: red !important;
}

/* Entrance Form */

.container-entrance {
    width: 400px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
}

.header-entrance {
    text-align: center;
    margin: 20px 0px 55px 0px;
}

.header-entrance.with-message {
    margin: 10px 0px 14px 0px;
}

.header-entrance>h2 {
    font-family: 'Monserrat';
    font-size: 40px;
    font-weight: 500;
}

.header-entrance>span {
    font-family: 'Monserrat';
    font-size: 20px;
    font-weight: 100;
}

.term-policy {
    /* font-family: 'Monserrat'; */
    /* font-style: italic; */
    font-size: 12px;
    margin-top: 15px;
}

.input-warning {
    font-family: 'Monserrat';
    font-size: 16px;
}

.btn-start-workflow.finish {
    background-color: rgb(252, 199, 92) !important;
    color: #1a67a3;
}

.alert-entrance {
    padding: 2px 10px;
    background-color: #fcc75c;
    border: 1px solid #c299475b;
    border-radius: 5px;
}

.alert-entrance>span {
    font-family: 'Monserrat';
    font-size: 18px;
}

.project-descripton-buttons {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.project-descripton-buttons>button {
    width: 100%;
    border-right: 1px solid white !important;
}


/**
check list
*/
.check-container {
    display: flex;
    flex-direction: row;
}

.check-left {
    display: flex;
    flex-direction: column;
    justify-content: start;
}

.check-left-mobile{
    display: none;
}

.check-left>button {
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    margin: 0px;
    padding: 0px 5px;
    border: 1px solid rgb(191, 191, 191);
    background-color: white;
    color: gray;
    height: 160px;
    font-weight: 600;
    font-family: "Monserrat";
}

.check-left.children>button {
    height: 140px;
}

.check-left>:hover {
    background-color: rgb(118, 155, 255);
    color: rgb(255, 255, 255);
}

.check-left .button-active {
    background-color: #1a67a3;
    color: white;
}

.check-left>button:disabled {
    background-color: rgb(230, 230, 230);
    color: gray;
}

.check-right {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 0px 100px;
    width: 100%;
}

.check-right>p {
    text-align: center;
}

.check-right>button {
    width: auto;
}

.check-list-info {
    padding: 0px 25px;
}

.check-tree-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 20rem;
    overflow: auto;
    border-bottom: 1px solid rgb(220, 219, 219);
}

.download-check-button {
    padding: 5px;
    border: 1px solid green;
    color: green;
    font-family: "Monserrat";
    cursor: pointer;
    height: 40px;
    width: 200px;
    margin-top: 20px;
}

.download-check-button:hover {
    font-weight: 600;
}

.actions-button {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

/* memberships*/

.modal-card-body.membership-modal {
    overflow: visible !important;
    background-color: #1a67a3;
}

.modal-card-head {
    background-color: #1a67a3;
    font-size: 18px;
    padding: 0px 15px;
    margin: 0px;
    text-align: right;
    border: 0px;
    justify-content: end;
    color: #fcc75c;
}

.membership-panel {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin: 15px 0px !important;
}

.membership-panel .membership-item {
    background-color: rgb(231, 231, 231);
    padding: 0px;
    width: 18vw;
    /* height: 55vh; */
    box-shadow: 5px 5px 5px rgb(83, 83, 83);
}

.membership-panel .membership-item .membership-item__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 15vh;
    padding: 25px;
    font-family: "Monserrat";
    background-color: #fcc75c;
    border-bottom: 1px solid #1a68a343;
}

.membership-panel .membership-item .membership-item__header>h2 {
    margin: 0px;
    font-size: 60px;
    font-weight: 800;
}

.membership-panel .membership-item .membership-item__header>small {
    font-weight: 800;
}

.membership-panel .membership-item .membership-item__content {
    height: 30vh;
    padding: 25px;
}


.membership-panel .membership-item .membership-item__footer {
    text-align: center;
    padding: 25px 25px;
}

.membership-panel .membership-item .membership-item__footer>button {
    width: 100%;
    margin: 0px;
    border-radius: 0px;
    background-color: #1a67a3;
    font-size: 24px;
    font-family: "Monserrat";

}

.membership-panel .membership-item .membership-item__content__item {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
}



.membership-panel .membership-item .membership-item__content__item>h4 {
    font-family: "Monserrat";
    font-size: 40px;
    font-weight: 700;
    text-align: center;
    width: 100%;
}

.membership-panel .membership-item .membership-item__content__item>p {
    margin: 0px 0px 5px 0px;
    font-size: 16px;
    font-family: "Monserrat";
}

.membership-panel .membership-item .membership-item__content__item>p>i {
    color: #1D6F42;
    font-weight: 800px;
    font-size: 18px;
}

.workflow-details-form {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    margin: 0px 5px;
    border: 1px solid rgb(221, 221, 221);
    width: 100%;
    overflow: auto;

}

.workflow-details-form-right {
    padding: 5px;
    width: 50%;
    border: 1px solid rgb(221, 221, 221);

}

.workflow-details-form-right>div>h5 {
    font-size: 18px;
    font-family: "Monserrat";
    font-weight: 600;
}

.workflow-details-form-right>div>p {
    font-size: 16px;
    font-family: "Monserrat";
}

.cont-workflow {
    position: absolute;
    display: flex;
    flex-direction: row;
    height: 100%;
}

.node-notification {
    position: relative;
    background-color: rgb(26 103 163);
    border: 1px solid rgb(179, 179, 179);
    padding: 10px;
    margin-top: 15px;

}

.node-notification>div>p {
    color: white;
    font-size: 18px;
    font-family: "Monserrat";
    font-weight: 600;
    margin: 0px;
}

.node-notification>div>p:last-child {
    font-size: 16px;
}

.node-notification>div>a {
    color: white !important;
    font-size: 18px;
    font-family: "Monserrat";
    font-weight: 600;
}

.node-notification.active {
    display: block;
}

.node-notification.inactive {
    display: none;
}

.cont-supplementary-button {
    padding: 5px;
}

.cont-supplementary-button>button {
    width: 15rem;
    border-radius: 0px;
    margin: 0px 5px;
    font-family: "Monserrat";

}

.cont-supplementary-button>button:first-child {
    background-color: rgb(26 103 163);

}

/* (min-width: 320px) and  */

@media (max-width: 1440px) {

    .workflow-body {
        min-height: auto;
        margin-bottom: 25px;
    }

    .workflow-description-div {
        margin-top: 15px;
    }

    .workflow-description-div .carousel .carousel-indicators {
        bottom: 10px !important;
    }

    .btn-start-workflow-div {
        width: 100%;
        padding: 10px;
    }

    .download-check-button {
        width: 100%;
        padding: 10px;
    }

    .workflow-container .small-popup .modal-card .modal-card-body>form>h3 {
        font-size: 22px;
    }

    .workflow-container .small-popup .modal-card .modal-card-body>form>p {
        font-size: 15px;
        width: 100%;
    }

    .workflow-details-description {
        width: 100%;
    }

    .workflow-details-phase-2 {
        width: 100%;
        margin-left: 0px;
    }

    .workflow-container .small-popup .modal-card {
        height: 600px !important;
    }

    .workflow-container .small-popup .modal-card .modal-card-body {
        flex-direction: column !important;
    }

    .workflow-image {
        /* display: none; */
        order: 1;
    }

    .workflow-description {
        width: 100%;
        order: 2;
    }

    .workflow-details {
        padding: 0px 15px !important;
    }

    .workflow-details-block {
        width: 100%;
        flex-direction: column;
        padding: 0px;
    }

    .learn-more-block {
        margin-top: 15px;
        width: 100%;
    }

    .workflow-details-phase-1>p {
        padding-right: 0px;
        margin-top: 10px;
    }

    .workflow-details-phase-2>p {
        padding-right: 0px;
        margin-top: 10px;
    }

    .workflow-controls {
        width: 100%;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 10px;
        padding: 0px;
    }

    .btn-workflow-download {
        width: 100% !important;
    }

    .workflow-controls>button {
        width: 100%;
        padding: 10px;
        margin-bottom: 5px;
    }

    .workflow-footer {
        position: relative;
    }


    .container-entrance {
        width: auto;
    }

    .project-descripton-buttons {
        flex-direction: column;
    }

    .project-descripton-buttons>button {
        border-right: none !important;
    }

    .cont-workflow {
        position: relative;
        flex-direction: column;
    }

    .workflow-details-form-right {
        width: 100%;        

    }
    .workflow-details-form {
        min-height: 25rem;
        max-height: 40rem;
        margin: 1.5rem 0rem;  
        align-items: normal;

    }

    .workflow-body {
        padding: 0px;
        flex-direction: column;
    }

    .check-left {
       
        display: none
       
    }

    .check-left-mobile{
        display: block;
        margin-top: 1.5rem;
        padding: 0px 15px;
    }

    .check-left>button {
        height: 12rem;
        writing-mode: horizontal-tb;
        transform: none;
        flex: 0 0 50%; 
        height: auto;
        padding: 15px;
        box-sizing: border-box;

    }

    .check-right {
        padding: 0px;
    }

    .actions-button {
        flex-direction: column;
    }

    .check-left>:hover {
        background-color: white;
    }

    .cont-supplementary-button{
        padding: 10px;
    }
    .cont-supplementary-button>button{
        margin-top: 5px;
        width: 100%;
    }


}


/* 
@media (min-width: 320px) and (max-width: 359px) {
    .workflow-body {
        min-height: 100vh;
    }
}

@media (min-width: 360px) {
    .workflow-body {
        min-height: 105vh;
    }
}

@media (min-width: 376px) {
    .workflow-body {
        min-height: 85vh;
    }
}

@media (min-width: 425px) {
    .workflow-body {
        min-height: 100vh;
    }
}

@media (min-width: 426px) and (max-width: 768px){
    .workflow-body {
        min-height: 100vh;
    }
}

@media (min-width: 769px) and (max-width: 1024px){
    .workflow-body {
        min-height: 100vh;
    }
}

@media (min-width: 1025px) and (max-width: 1440px){
    .workflow-body {
        min-height: 100vh;
    }
} */