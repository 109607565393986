.shared-tittle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid rgb(204, 204, 204);
  margin-bottom: 25px;
}

.shared-tittle>h5 {
  font-size: 15px;
}

.shared-tittle>i {
  font-style: normal;
  font-size: 15px;
  margin: 0px 10px;
}

.shared-body {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 25px;

}

.shared-body>button[aria-label="whatsapp"] {
  color: #00a884 !important;

}

.shared-body>button[aria-label="telegram"] {
  color: #0088cc !important;
}

.shared-body>button[aria-label="linkedin"] {
  color: #0a66c2 !important;
}

.shared-body>button[aria-label="facebook"] {
  color: #0866ff !important;
}

.shared-body>button[aria-label="email"] {
  color: #f8c64a !important;
}

.shared-body:last-child {
  border-bottom: 1px solid rgb(204, 204, 204);
  padding-bottom: 25px;
}

.shared-body>button {
  font-size: 35px !important;
}

.title-panel>div>span>i {
  font-size: 15px;
  font-style: normal;
  color: #1a67a3;
  cursor: pointer;
  margin: 0px 10px;
}


.row-card-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0px !important;
  /* padding: 0px !important; */
  padding: 10px 25px;
  height: auto;
  max-height: 75vh;
  overflow-y: auto;
  overflow-x: hidden;
  /* scrollbar-width: none; */
}

.row-card-list.no-scroll {
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}

/* Estilos para navegadores webkit (Chrome, Safari, etc.) */
::-webkit-scrollbar {
  width: 10px;
  /* Ancho de la barra de desplazamiento */
}

::-webkit-scrollbar-thumb {
  background-color: #ccc;
  /* Color del thumb (parte deslizante) */
  border-radius: 5px;
  /* Borde redondeado */
}

::-webkit-scrollbar-track {
  background-color: #f0f0f0;
  /* Color del track (fondo de la barra de desplazamiento) */
  border-radius: 5px;
  /* Borde redondeado */
}

.card-license-info {
  padding: 10px;
  margin-top: 20px;
  border-radius: 5px;
  border: 1px solid rgb(161, 161, 161);
  border-left: 5px solid #1a67a3;
  height: 28vh;
  cursor: pointer;
  background-color: rgb(250, 250, 250);
  width: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-right: 30px;

}

.card-license-info.team-card {
  cursor: auto;
}

.card-license-info.team-card:hover {
  box-shadow: none !important;
}

.card-license-info:hover {
  box-shadow: 5px 5px 5px rgb(212, 212, 212);
}



.card-license-info>div>span {
  font-weight: 600;
  color: rgb(114, 114, 114);
  font-size: 13px;
}

.card-license-info>div>span[data-type="card-text"] {
  font-weight: 500;
  color: rgb(50, 50, 50);
  font-size: 13px !important;
}

.card-license-info>div:last-child {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  /* border-top: 1px solid rgba(128, 128, 128, 0.162); */
}

.card-license-info>div:last-child>button {
  font-size: 20px;
  margin: 5px 10px;
}

.team-item {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.team-item>.description {
  margin-top: 5px;
  text-align: justify;
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;

}

.team-item>span:first-child {
  font-weight: 600;
  color: rgb(114, 114, 114);
  font-size: 14px;
  font-family: 'Monserrat';
}

.team-item>span:last-child {
  font-weight: 500;
  color: rgb(50, 50, 50);
  font-size: 14px !important;
  font-family: 'Monserrat';
}

.delete-modal-body {
  text-align: left;
  overflow: hidden;

}

.div-buttons-modal {
  display: flex;
  flex-direction: row;
  justify-content: right;
}

.div-buttons-modal>button {
  margin-right: 10px;
  width: 15%;
  padding: 5px;
  font-size: 18px;
  font-weight: 600;
  border: 1px solid;
  border-radius: 5px;
}

.div-buttons-modal>button:first-child {
  background-color: rgb(211, 10, 10) !important;
  color: white;
}

.project.card-license-info {
  border-radius: 0px;
  height: 20vh;
}

.project.card-license-info:hover {
  box-shadow: 5px 5px 5px rgb(183, 183, 183)!important;
}

.team-item.project-item {
  justify-content: space-between !important;
  /* justify-content: end !important; */
  align-items: center !important;
}

.team-item.project-item>span {
  font-family: 'Monserrat';
  font-size: 15px;
  font-weight: 100;
  cursor: pointer;
  color: #0088cc;

}

.team-item.project-item>span:last-child {
  color: rgb(211, 10, 10);
}

.team-item.project-item>button {
  margin: 0px !important;
}

.delete-text {
  font-family: 'Monserrat';
  text-align: center;
  margin-bottom: 15px;
}

.btn-fill.exit-trade-modal {
  background-color: #dc3545!important;
}

.params-list {
  background-color: rgb(237, 237, 237);
  border-left: 2px solid #0088cc;
  margin-top: 1px;
  display: flex;
  justify-content: space-around;
}

.params-list>span {
  font-family: 'Monserrat';
  padding: 2px 5px;
}

.params-list>span:first-child {
  width: 100%;
  text-align: start;
}

.params-list>span:nth-child(2) {
  width: 100%;
  text-align: start;
}
.params-list>span:last-child {
  color: red;
  cursor: pointer;
}


.map-button.tree-map-button {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.map-button.tree-map-button button {
  margin: 0px !important;
  background-color: #1a67a3;
  color: white !important;
}

.map-button.tree-map-button button:hover {
  color: white !important;
}

.map-button.tree-map-button.exit-trade-modal {
  border: 1px solid red;
  background-color: white !important;
  color: red !important;
}

.map-button.tree-map-button.exit-trade-modal:hover {
  background-color: rgb(211, 10, 10) !important;
}


.project-param-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.project-param-title>h3 {
  margin: 0px;
  font-size: 22px;
  font-family: "Monserrat";
}
.project-param-title>span {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px;
  font-size: 18px;
  font-family: "Monserrat";
  color: #1a67a3;
  font-weight: 600;
  cursor: pointer;
}

.div-add-param-link {
  margin-top: 15px;
  cursor: pointer;
}

.div-add-param-link>span {
  font-size: 16px;
  font-family: "Monserrat";
  color: #1a67a3;
}


@media (max-width: 992px) {
  .card-license-info {
    margin-right: 0px;
    width: 100%;
  }

  .row-card-list {
    padding: 10px 0px;
    max-height: 100%;
    height: auto;
    overflow-y: auto;
    overflow-x: auto;
  }

  .div-buttons-modal>button {
    width: 100%;
  }

  .map-button.tree-map-button {
    flex-direction: column;
  }

  .map-button.tree-map-button>button {
    margin-bottom: 5px !important;
    width: 100%;
  }

}