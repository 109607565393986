.sidebar-wrapper {
  background-color: #eee;
  padding: 22px 0;
  position: relative;
}
.footer-left {
  padding: 22px 0;
  position: relative;
 
}

.footer-left>p {
  padding: 0px 30px;
  margin: 0px;
  font-size: 14px;  
  font-family: 'Monserrat';
  color: #fff;
}

.sidebar-dropdown {
  position: relative;
  border-bottom: solid 1px #e1e1e1;
}

.sidebar-submenu a {
  padding-left: 56px;
  border: 0;
}

.sidebar-dropdown .fa-angle-down {
  position: absolute;
  right: 10px;
  top: 15px;
}

.services_custom_top {
  margin: 18px 0;
  text-align: center;
}

.services_custom_top h3 {
  /* color: #363377; ticket # 784 requires to change is color to #0018A8 */
  color: #1a67a3;
  font-size: 34px;
  font-weight: 700;
}

.services_custom_top p {
  /* color: #363377; ticket # 784 requires to change is color to #0018A8 */
  color: #1a67a3;
}

.services_custom_top a {
  padding: 2px 15px;
  display: inline-block;
  text-align: center;
  /*  border: solid 1px #363377;  ticket # 784 requires to change is color to #0018A8*/
  border: solid 1px #1a67a3;
  /* color: #363377; ticket # 784 requires to change is color to #0018A8 */
  color: #1a67a3;
  transition: 0.3s;
  margin-top: 14px;
  font-style: italic;
  text-decoration: underline;
}

.services_custom_top a:hover {
  background-color: #fcc75c
    /* #fab62f */
  ;
  color: #fff;
  transition: 0.3s;
}

/*progressbar*/
#progressbar {
  margin-bottom: 10px;
  overflow: hidden;
  color: lightgrey;
}

#progressbar .active {
  color: #000000;
}

#progressbar li {
  list-style-type: none;
  font-size: 12px;
  width: 25%;
  float: left;
  position: relative;
}

#progressbar li a {}

/*Icons in the ProgressBar*/
#progressbar #account:before {
  font-family: FontAwesome;
  content: "\f023";
}

#progressbar #personal:before {
  font-family: FontAwesome;
  content: "\f007";
}

#progressbar #payment:before {
  font-family: FontAwesome;
  content: "\f09d";
}

#progressbar #confirm:before {
  font-family: FontAwesome;
  content: "\f00c";
}

/*ProgressBar before any progress*/
#progressbar li:before {
  content: "";
  width: 30px;
  height: 30px;
  line-height: 45px;
  display: block;
  font-size: 18px;
  color: #ffffff;
  background: lightgray;
  border-radius: 50%;
  margin: 0 auto 10px auto;
  padding: 2px;
}

/*ProgressBar connectors*/
#progressbar li:after {
  content: "";
  width: 100%;
  height: 2px;
  background: lightgray;
  position: absolute;
  left: 0;
  top: 17px;
  z-index: 1;
}

/*Color number of the step and the connector before it*/
#progressbar li.active:before,
#progressbar li.active:after {
  background: #fcc75c
    /* #fab62f */
  ;
}

#progressbar li.completed:before,
#progressbar li.completed:after {
  /* color: #363377; ticket # 784 requires to change is color to #0018A8 */
  color: #1a67a3;
}

#progressbar li.active a {
  color: #fcc75c
    /* #fab62f */
  ;
}

/*Imaged Radio Buttons*/
.radio-group {
  position: relative;
  margin-bottom: 25px;
}

.radio {
  display: inline-block;
  width: 204;
  height: 104;
  border-radius: 0;
  background: lightblue;
  box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  cursor: pointer;
  margin: 8px 2px;
}

.radio:hover {
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.3);
}

.radio.selected {
  box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.1);
}

/*Fit image in bootstrap div*/
.fit-image {
  width: 100%;
  object-fit: cover;
}

.left_dashboard_menu {
  background-color: #1a67a3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.left_dashboard_menu>.sidebar-wrapper {
  background-color: #1a67a3;
}

.left_dashboard_menu>.sidebar-wrapper>.menu_barr>.p_tabs>a {
  color: white;
  border: 0px
}

.menu-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-image>span:first-child {
  display: none;
}

.account-options {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  /* justify-content: space-between; */
  gap: 1em;
  margin-top: 30px;
  padding: 10px 25px;
}

.account-options.account-reputation {
  justify-content: start;
}


.option-panel {
  /* flex: 1 0 8rem; */
  border: 1px solid rgb(185, 185, 185);
  height: 20vh;
  width: 20rem;
  padding: 40px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border-radius: 5px; */
  border-left: 5px solid #1a67a3;
  background-color: rgb(250, 250, 250);
  cursor: pointer;
}

.option-panel.reputation {
  flex: none !important;
  width: 20rem !important;
}

.option-panel:hover {
  box-shadow: 5px 5px 5px rgb(212, 212, 212);
}

.option-panel>span {
  margin-top: 20px;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  font-family: 'Monserrat';
}

.title-module {
  font-size: 20px;
  border-bottom: 1px solid black;
}

.title-module>span {
  color: #1a67a3;
  cursor: pointer;
}

.option-panel-survey {
  /* flex: .5 0 8rem; */
  border: 1px solid rgb(185, 185, 185);
  height: auto;
  width: auto;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border-left: 5px solid #1a67a3;
  background-color: rgb(250, 250, 250);
  cursor: pointer;
}

.survey-options {
  margin-top: 10px;
  display: flex !important;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
  width: 100%;
}

.survey-options:last-child {
  margin-top: 20px;
}

.survey-options>label {
  font-size: 15px;
}

.survey-options>button {
  width: 100%;
  background-color: #1a67a3 !important;
}

.title-panel {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1em;
  padding: 0px 25px;
  margin-right: 30px;
}

.title-panel>div>span>i {
  font-size: 15px;
  font-style: normal;
  color: #1a67a3;
  cursor: pointer;
  margin: 0px 10px;
}

.buttons-top {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 95%;
  padding: 15px 15px 0px 15px;
}

.buttons-top>span {
  font-size: 25px;
}

.logout-option {
  display: none !important;
}

.logout-optio-pcn {
  display: block !important;
}

.popover-content {
  width: 150px;
  border: 1px solid rgb(218, 218, 218);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 5px 5px 5px rgb(188, 188, 188);
  z-index: 1;
  background: white;

}

.popover-content>div {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  align-items: center;
  background-color: rgb(233, 233, 233);
  padding: 10px;
  width: 100%;
}

.popover-content>div>span:first-child {
  font-weight: 700;
  text-transform: uppercase !important;
  max-width: 100%;
  text-align: center;
}

@media (max-width: 1200px) {
  .sidebar-submenu a {
    padding-left: 35px;
  }
}

@media (max-width: 992px) {

  .buttons-top {
    display: none;
  }

  .logout-option {
    display: block !important;
  }

  .logout-optio-pcn {
    display: none !important;
  }

  .option-panel {
    flex: none;
    border: 1px solid rgb(185, 185, 185);
    height: 5vh;
    width: 100%;
    padding: 40px;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    border-radius: 5px;
    border-left: 5px solid #1a67a3;
    background-color: rgb(250, 250, 250);
    cursor: pointer;
  }

  .option-panel>span {
    margin-top: 0px;
    margin-left: 25px;
    font-size: 18px;
  }

  .option-panel>img {
    width: 13%;
  }

  .menu-image {
    justify-content: left;
  }

  .menu-image>span:first-child {
    display: block;
    margin: 0px 15px;
    font-size: 25px;
    color: white;
    font-weight: 800;
  }

  .option-panel.reputation {
    flex: none !important;
    width: 100% !important;
  }

  .footer-left {
    text-align: center;
   
  }

  .title-panel {
    
    padding: 0px;
    margin-right:0px;
  }

}