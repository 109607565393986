/* Body */
.landing-body {
    background-color: #f4f9fc;
}


.login-btn {
    background-color: #ff7f00;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
}

.login-btn:hover {
    background-color: #e86c00;
}

.landing-card {
    display: flex;
    flex-direction: row;
}

.landing-card>div {
    width: 50%;
    margin: 5px 5px;
    padding: 15px 10px;
}

.container-timeline {
    font-family: 'Roboto', sans-serif;
    display: flex;
    flex-wrap: wrap;
    max-width: 1000px;
    margin: 0 auto;
}

.single-box {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    width: 100%;
}

.date-area {
    display: flex;
    background: linear-gradient(to right, #e7e7e7 45%, #000 45%, #000 45%, #e7e7e7 50%);
    order: 2;
    text-align: center;
    color: #fff;
    font-size: 28px;
    flex-basis: 100px;
}

.date-area>span {
    font-size: 16px;
    /* line-height: 70px; */
    margin: auto;
    background: #14b6c1;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    text-align: center;
    padding: 10px 0px;
}

.content-time {

    padding: 20px;
    border-radius: 25px;
    color: #000000;
    order: 3;
    width: 80%;
    padding-left: 10px;
    background: #fff;
}

.content-time {
    margin-bottom: 3%;
}

.content-time h2 {
    margin: 0;
    font-size: 24px;
}

.content-time h3 {
    margin: 5px;
    font-size: 14px;
    color: #000;
}

@media (min-width: 640px) {

    .content-time,
    .custom {
        width: 45%;
    }

    .box-right .content-time {
        order: 1;
        padding-right: 10px;
        border-radius: 25px;
    }

    .box-right .custom {
        order: 3;
    }
}


/* Responsive Design */
@media (max-width: 768px) {


    .login-btn {
        width: 100%;
    }

    .landing-card {
        flex-direction: column;
    }

    .landing-card>div {
        width: 100%;
        margin: 5px 0px;
    }



}

/* Global container */
.landing-body .section-landing .container {
    width: 100%;
    max-width: 1010px;
    margin: 0 auto;
    padding: 0 20px;
}

.landing-body .section-landing.sl-1 .container {
    max-width: 1300px;
}

/* Section 1 - Main */
.landing-body .section-landing {
    /* background: linear-gradient(to bottom, #ffffff, #f4f9fc); */
    text-align: center;
    padding: 80px 20px;
}

.landing-body .section-landing h1 {
    font-family: "Gotham", sans-serif;
    line-height: 110%;
    font-size: 5rem;
    font-weight: 900;
    color: rgb(29, 46, 59);
}

.landing-body .section-landing p {
    font-size: 1.3rem;
    color: #555;
    margin-bottom: 30px;
    max-width: 860px;
    margin-left: auto;
    margin-right: auto;
    font-family: 'Inter', sans-serif;
    line-height: 1.6;
    color: #333;
}

.landing-body .cta-button {
    background-color: #ffaf00;
    /* Naranja brillante */
    width: 400px;
    color: #000000;
    border: none;
    padding: 15px 30px;
    font-size: 1rem;
    border-radius: 30px;
    cursor: pointer;
    transition: background-color 0.3s;
    display: inline-block;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    font-family: 'Inter', serif;
}

.landing-body .cta-button:hover {
    background-color: #e86c00;
    font-family: "Inter", serif;
}

.font-green {
    color: #1aa354 !important
}

.section-one-paraph {
    margin-top: 80px !important;
    font-family: "Inter", serif !important;
    font-size: 31px !important;
    line-height: 1.2 !important;
}

.card-union {
    width: 800px;
    height: auto;
}




/* Headline styles */
.landing-body h1,
.landing-body h2,
.landing-body h3 {
    font-weight: 700;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
    font-family: "Inter", serif;
    line-height: 1.6;
}

.landing-body h2 {
    font-size: 2.5rem;
    font-family: "Inter", serif;
    line-height: 1.6;
}

.landing-body h3 {
    font-size: 2rem;
    color: #555;
    font-family: "Inter", serif;
    line-height: 1.6;
}

/* Section layout */
/* section {
    padding: 60px 20px;
} */


/* Boxed Section Styles (except for Section 1) */
.landing-body .boxed-section {
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 40px 20px;
    margin: 20px 0;
    max-width: 1200px;
    /* Limit the width of the boxed sections */
    margin-left: auto;
    margin-right: auto;
    /* Center the section */
}

.landing-body .boxed-section h2 {
    font-size: 2.5rem;
    /* Título principal */
    color: #333;
    font-family: "Inter", serif;
    line-height: 1.6;
}

.landing-body .boxed-section h3 {
    font-size: 2rem;
    /* Subtítulos más pequeños */
    color: #333;
    font-family: "Inter", serif;
    line-height: 1.6;
}

.landing-body .boxed-section p {
    font-size: 1.2rem;
    color: #555;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    font-family: "Inter", serif;
    line-height: 1.6;
}

.landing-body .boxed-section ul {
    list-style: none;
    padding-left: 0;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    font-family: "Inter", serif;
    line-height: 1.6;
}

.landing-body .boxed-section ul li {
    margin-bottom: 15px;
    font-size: 1.2rem;
    color: #333;
    display: flex;
    align-items: center;
    font-family: "Inter", serif;
    line-height: 1.6;
}

.landing-body .boxed-section ul li::before {
    content: "➤";
    color: #ff7f00;
    margin-right: 10px;
    font-family: "Inter", serif;
    line-height: 1.6;
}

.landing-body .boxed-section .cta-button {
    background-color: #ff7f00;
    color: #fff;
    border: none;
    padding: 12px 30px;
    font-size: 1rem;
    border-radius: 30px;
    cursor: pointer;
    transition: background-color 0.3s;
    display: block;
    text-align: center;
    margin: 30px auto 0;
    font-family: "Inter", serif;
}

/* Final Call to Action */
.landing-body .final-cta {
    background-color: #ffffff;
    padding: 60px 20px;
    text-align: center;
}

.landing-body .final-cta h2 {
    font-size: 2.5rem;
    color: #333;
    font-family: "Inter", serif;
    line-height: 1.6;
}

.landing-body .final-cta p {
    font-size: 1.2rem;
    color: #555;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    font-family: "Inter", serif;
    line-height: 1.6;
}

.landing-body .final-cta .cta-button {
    background-color: #ff7f00;
    color: #fff;
    border: none;
    padding: 12px 30px;
    font-size: 1rem;
    border-radius: 30px;
    cursor: pointer;
    transition: background-color 0.3s;
    display: inline-block;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    font-family: "Inter", serif;
}

.landing-body .final-cta .cta-button:hover {
    background-color: #e86c00;
    font-family: "Inter", serif;
}

.img-features {
    margin: 25px 0px 50px 0px;
}

.card-features {
    border-radius: 25px;
    padding: 20px 15px;
}

.card-features-frameworks {
    border-radius: 25px;
    padding: 20px 15px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
}

.container-card {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.container-card>div {
    width: 29rem;
    margin: 10px 10px;
}

.timeline-ul,
.framework-ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.timeline-ul li,
.framework-ul li {
    position: relative;
    padding-left: 20px;
}

.framework-ul li {
    margin-top: 10px;
}

.framework-ul .popover-li {
    margin-top: 0px;
    font-size: 13px;
}

.timeline-ul li:before,
.framework-ul li:before {
    content: ">";
    position: absolute;
    left: 0;
    /* Espacio entre el símbolo y el texto */
    color: #ff7f00;
    /* Cambia el color del símbolo */
}

.modal-content-landing {
    width: 40% !important;
    background-color: white !important;
}

.modal-landing-body {
    position: relative;
}

.close-modal-landing {
    position: absolute;
    right: 0;
    width: 25px;
    background-color: unset;
    border: 0px;
    font-size: 20px;
    margin: 10px 25px;
}

/* Responsive design */


/* Ajustes generales */
.landing-body .cta-button {
    padding: 12px 25px;
    font-size: 30px;
    font-family: "Inter", serif;
}

.landing-body h2 {
    font-size: 2rem;
    font-family: "Inter", serif;
    line-height: 1.6;
}

.landing-body p {
    font-size: 1rem;
    font-family: "Inter", serif;
    line-height: 1.6;
}

.landing-body .container {
    padding: 0 15px;
}

.landing-body .section-landing,
.landing-body .boxed-section,
.landing-body .final-cta {
    padding-left: 20px;
    padding-right: 20px;
}


.landing-body footer {
    background-color: #e7eff6;
    /* Un azul claro que contrasta con el fondo */
    color: #333;
    /* Texto oscuro para buena legibilidad */
    padding: 20px;
    text-align: center;
}

.landing-body footer img {
    max-width: 150px;
    /* Tamaño ajustado para el logo */
    margin-bottom: 15px;
}

.landing-body footer p {
    font-size: 0.9rem;
    margin-bottom: 15px;
    font-family: "Inter", serif;
    line-height: 1.6;
    color: #333;
}

.landing-body footer .footer-links {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-bottom: 10px;
}

.landing-body footer .footer-links a {
    color: #1a67a3;
    text-decoration: none;
    font-size: 0.7rem;
    transition: color 0.3s;
    font-family: "Inter", serif;
    line-height: 1.6;
}

.landing-body footer .footer-links a:hover {
    color: #ff7f00;
    font-family: "Inter", serif;
    line-height: 1.6;
}

.landing-body footer .social-icons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 15px;
}

.landing-body footer .social-icons a {
    color: #1a67a3;
    font-size: 1.2rem;
    transition: color 0.3s;
    font-family: "Inter", serif;
    line-height: 1.6;
}

.landing-body footer .social-icons a:hover {
    color: #ff7f00;
    font-family: "Inter", serif;
    line-height: 1.6;
}

@media (max-width: 768px) {

    .modal-content-landing {
        width: 95% !important;
        background-color: white !important;
    }

    .card-features {
        height: auto !important;
        margin: 15px 0px;
    }

    .right .login-btn {
        font-size: 0.9rem;
        /* Reducir tamaño de fuente */
        padding: 4px 4px;
        /* Reducir los lados del botón */
        margin-left: 0px;
        /* Empujar el botón hacia la derecha */
        margin-right: 0px;
        /* Mantener un pequeño margen a la derecha */
        font-family: "Inter", serif;
        line-height: 1.6;
    }

    .card-union {
        width: auto;
        height: 1000px;
    }

    .card-union>div>div>span {
        font-size: 18px !important;
    }


    /* Ajustes de secciones para pantallas pequeñas */
    .landing-body .section-landing h1 {
        font-size: 3rem;
        /* Reducir tamaño del título */
        line-height: 1.2;
        /* Ajustar el interlineado */
        font-family: "Inter", serif;
    }

    .landing-body .section-landing p {
        font-size: 1rem;
        /* Reducir tamaño del texto */
        margin-bottom: 20px;
        /* Espaciado más compacto */
        font-family: "Inter", serif;
        line-height: 1.6;
    }

    .landing-body .boxed-section h2 {
        font-size: 1.8rem;
        /* Tamaño de títulos en móviles */
        font-family: "Inter", serif;
        line-height: 1.6;
    }

    .landing-body .boxed-section h3 {
        font-size: 1.5rem;
        /* Subtítulos más pequeños */
        font-family: "Inter", serif;
        line-height: 1.6;
    }

    .landing-body .boxed-section ul li {
        font-size: 1rem;
        /* Reducir el tamaño de la lista */
        font-family: "Inter", serif;
        line-height: 1.6;
    }

    .landing-body .cta-button {
        padding: 10px 20px;
        /* Ajustar tamaño de botones */
        width: 80%;
        font-size: 25px !important;
        font-family: "Inter", serif;
    }

    .landing-body .final-cta h2 {
        font-size: 1.8rem;
        /* Reducir título final */
        font-family: "Inter", serif;
        line-height: 1.6;
    }

    .landing-body .final-cta p {
        font-size: 1rem;
        font-family: "Inter", serif;
        line-height: 1.6;
        color: #333;
        /* Reducir texto del llamado final */
    }

    .framework-ul .popover-li {
        margin-top: 10px;
    }



}


@media (max-width: 390px) {
    .section-landing.sl-1 .container>h1 {
        font-size: 50px !important;
    }

    .landing-body .cta-button {
        font-size: 18px !important;
    }
}