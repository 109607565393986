.form-inner.form-inner-ext {
    margin: 0px !important;
    padding: 0px !important;
}

.form-main.form-main-ext {

    border: 0 !important;
    box-shadow: none;
    padding: 0px !important;

}

.form-fields.form-fields-ext {

    margin: 0px;

}

.captcha {
    display: flex;
    justify-content: center;
    position: relative;
}