/* Tree trade design */

#fontSizeWrapper {
  font-size: 16px;
}

#fontSize {
  width: 100px;
  font-size: 1em;
}

/* ————————————————————–
  Tree core styles
*/
.tree {
  margin: 1em;
}

.tree .control-node {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}

.tree .control-node~ul {
  display: none;
}

.tree .control-node:checked~ul {
  display: block;
}

/* ————————————————————–
  Tree rows
*/
.tree li {
  line-height: 1.2;
  position: relative;
  padding: 0 0 1em 1em;
}

.tree {
  list-style-type: none !important;
}

.tree ul {
  list-style-type: none !important;
}

.tree ul li {
  padding: 1em 0 0 1em;
}

.tree>li:last-child {
  padding-bottom: 0;
}

/* ————————————————————–
  Tree labels
*/
.tree_label {
  position: relative;
  display: inline-block;
  background: #fff;
  font-family: "monserrat";
  font-size: 16px;
}

.tree_label_child {
  position: relative;
  display: inline-block;
  background: #fff;
  font-family: "monserrat";
  font-size: 16px;
}

label.tree_label {
  cursor: pointer;
  margin: 0.5px;
  font-family: "monserrat";
  font-size: 16px;
}

label.tree_label_child {
  cursor: pointer;
  margin: 0.5px;
  font-family: "monserrat";
  font-size: 16px;
}

label.tree_label:hover {
  color: #666;
}

label.tree_label_child:hover {
  color: #666;
}

/* ————————————————————–
  Tree expanded icon
*/
label.tree_label:before {
  background: #1a67a3;
  color: #fff;
  position: relative;
  z-index: 1;
  float: left;
  /* margin: 0 2em 0 -2.8em; */
  margin: 0 1em 0 -2.8em;
  width: 1em;
  height: 1em;
  /* border-radius: 1em; update */
  content: '+';
  /* font-size: 18px; */
  text-align: center;
  line-height: .99em;
}

/* ————————————————————–
  Tree expanded icon
*/
label.tree_label.concept:before {
  background: #1a67a3;
  color: #fff;
  position: relative;
  z-index: 1;
  float: left;
  /* margin: 0 2em 0 -2.8em; */
  margin: 0 1em 0 -2em;
  width: 1em;
  height: 1em;
  /* border-radius: 1em; update */
  content: '+';
  /* font-size: 18px; */
  text-align: center;
  line-height: .99em;
}

label.tree_label_child:before {
  background: #000;
  color: #fff;
  position: relative;
  z-index: 1;
  float: left;
  /* margin: 0 2em 0 -2.8em; */
  margin: 0 1em 0 -2em;
  width: 1em;
  height: 1em;
  border-radius: 1em;
  content: '+';
  text-align: center;
  line-height: .9em;
}

.control-node:checked~label.tree_label:before {
  content: '–';
}

.control-node:checked~label.tree_label_child:before {
  content: '–';
}

/* ————————————————————–
  Tree branches
*/
.tree li:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -.5em;
  display: block;
  width: 0;
  border-left: 1px solid #777;
  content: "";
}

.tree_label .concept:after {
  position: absolute;
  top: 0;
  left: -1.5em;
  /* left: -2.3em; */
  display: block;
  height: 0.5em;
  width: 1em;
  border-bottom: 1px solid #777;
  border-left: 1px solid #777;
  border-radius: 0 0 0 .3em;
  content: '';
}

.tree_label:after {
  position: absolute;
  top: 0;
  /* left: -1.5em; */
  left: -2.3em;
  display: block;
  height: 0.5em;
  width: 1em;
  border-bottom: 1px solid #777;
  border-left: 1px solid #777;
  border-radius: 0 0 0 .3em;
  content: '';
}

.tree_label_child:after {
  position: absolute;
  top: 0;
  /* left: -1.5em; */
  left: -2.3em;
  display: block;
  height: 0.5em;
  width: 1em;
  border-bottom: 1px solid #777;
  border-left: 1px solid #777;
  border-radius: 0 0 0 .3em;
  content: '';
}

.tree_label_child .concept:after {
  left: -1.5em;
}

label.tree_label:after {
  border-bottom: 0;
}

label.tree_label_child:after {
  border-bottom: 0;
}

.control-node:checked~label.tree_label:after {
  border-radius: 0 .3em 0 0;
  border-top: 1px solid #777;
  border-right: 1px solid #777;
  border-bottom: 0;
  border-left: 0;
  bottom: 0;
  top: 0.5em;
  height: auto;
}

.control-node:checked~label.tree_label_child:after {
  border-radius: 0 .3em 0 0;
  border-top: 1px solid #777;
  border-right: 1px solid #777;
  border-bottom: 0;
  border-left: 0;
  bottom: 0;
  top: 0.5em;
  height: auto;
}

.tree li:last-child:before {
  height: 1em;
  bottom: auto;
}

.tree>li:last-child:before {
  display: none;
}

.tree_custom {
  display: block;
  background: #eee;
  padding: 1em;
  border-radius: 0.3em;
}

.small-popup .modal-card {
  text-align: start !important;
}

.map-button {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.map-button button {
  margin: 0px !important;
  background-color: #1a67a3 !important;
  color: white !important;
}

.map-button button:hover {
  color: white !important;
}

.map-button.exit-trade-modal {
  border: 1px solid red;
  background-color: white !important;
  color: red !important;
}

.map-button.exit-trade-modal:hover {
  background-color: rgb(211, 10, 10) !important;
}

.title-tree-params {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.title-tree-params>h4 {
  font-family: "Monserrat";
  font-size: 20px;
  margin: 0px;

}

.title-tree-params>span {
  font-family: "Monserrat";
  margin: 0px;
  cursor: pointer;
}

.cont-input-param-check {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}

.cont-input-param-check .form-check {
  margin-right: 15px;
}

.btn-fill {
  width: 133px;
  padding: 10px 15px;
  color: #fff;
  background-color: #1a67a3 !important;
  border: 1px solid #fff;
}

.btn-fill.exit-trade-modal {
  background-color: #dc3545!important;
}

.params-cont{
  padding: 0px 25px;
}

.link-update-param{
  color:#1a67a3;
  font-family:"Monserrat";
  font-weight:"600";
  cursor: pointer;
}

.cont-suplementary{
  height: 400px;
}

@media (max-width: 992px) {
  .tree_label {
    font-size: 14px !important;
  }

  .tree_label_child {
    font-size: 14px !important;
  }

}

@media (max-width: 768px) {

  .tree_label_child.concept:after {
    left: -1.7em;
  }

  /* ————————————————————–
    Tree expanded icon
  */
  label.tree_label:before {
    margin: 0 1em 0 -3.1em;
  }

  .control-node:checked~label.tree_label:after {
    left: -2.15rem;
  }

  .tree li:last-child:before {
    height: 1.5em;
  }
}