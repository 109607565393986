.sections>h2 {
    font-family: 'Montserrat';
}

.container-fluid {
    padding: 0px !important;
}

.section-1 {
    background-color: #1a67a3;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.section-1>.head {
    padding: 20px 70px !important;
    width: 100%;
}

.head {
    text-align: left;
}

.head-section-2 {
    text-align: center !important;
}

.head-risk {
    text-align: center !important;
}

.head-section-3 {
    text-align: center !important;
}

.head-section-4 {
    text-align: center !important;
}

.head-section-6 {
    text-align: center !important;
}

.head-section-8 {
    text-align: center !important;
}

.head>.head-col {
    padding: 0px 0px 0px 120px;
}

.head>.head-col-section-5 {
    padding: 0px 0px 0px 120px;
}

.head>.head-col>h1 {
    font-size: 4rem;
    text-align: left;
    font-weight: 800;
    margin: 40px 0px;
    color: white;
    width: 100%;
    font-family: 'Montserrat';
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.head>.head-col>h2 {
    font-size: 2.5rem;
    text-align: left;
    font-weight: 800;
    margin: 40px 0px;
    color: white;
    width: 100%;
    font-family: 'Montserrat';
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.head>.head-col>h3 {
    font-size: 2rem;
    text-align: left;
    font-weight: 800;
    margin: 40px 0px;
    color: white;
    width: 100%;
    font-family: 'Montserrat';
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.head>.head-col>h5 {
    font-size: 20px;
    text-align: left;
    font-weight: 800;
    margin: 40px 0px;
    color: white;
    width: 100%;
    font-family: 'Montserrat';
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.section-one-body-cards>p {
    font-size: 16px;
    text-align: center;
    font-weight: 600;
    color: rgb(0, 0, 0) !important;
    width: 100%;
    font-family: 'Montserrat';

}

.section-one-body-cards>p>h6 {
    font-size: 18px;
    text-align: left;
    font-weight: 800;
    margin: 15px 0px;
    color: rgb(0, 0, 0);
    width: 100%;
    font-family: 'Montserrat';
    /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); */
}

.head>.head-col>strong {
    font-size: 1.5rem;
    font-weight: 800;
    display: block;
    width: 100%;
    color: white;
    font-family: 'Montserrat';
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.head-col-img {
    position: relative;
    display: inline-block;
    margin-top: 30px;

}

.head-col-img>img {
    border: 1px solid black;
    border-radius: 25px;

}

.head-col-img::after {
    content: "";
    position: absolute;
    bottom: 0%;
    left: 20%;
    right: 0;
    width: 350px;
    height: 40px;
    background: linear-gradient(to bottom, transparent, #1a67a3 50%);
}


.head-section-2>h2 {
    font-size: 3.3rem;
    text-align: center;
    font-weight: 700;
    margin: 0px 0px 10px 0px;
    color: #1a67a3;
    font-family: 'Montserrat';
    /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); */
}

.head-section-2>strong {
    margin-top: 30px;
    font-size: 1.5rem;
    font-weight: 700;
    display: block;
    color: #1a67a3;
    font-family: 'Montserrat';
    /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); */
}


.head>h2 {
    font-size: 3.2rem;
    text-align: left;
    font-weight: 800;
    margin: 40px 0px;
    color: white;
    width: 510px;
    font-family: 'Montserrat';
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.head>strong {
    font-size: 1.5rem;
    font-weight: 800;
    display: block;
    width: 510px;
    color: white;
    font-family: 'Montserrat';
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}


.section-one-body {
    text-align: left;
    padding: 0px 130px;
    background-color: #dfdfdf;
}

.section-one-body>.section-one-body-cards>p {
    color: white;
    font-size: 1rem;
    /* margin-top: 20px; */
    width: 64%;
    padding: 0px 0px;
    line-height: 35px;
    font-family: 'Montserrat';
}

.section-one-body-cards {
    margin: 40px 0px 15px 0px;
}

.individual-card {
    background-color: none;
    margin: 0px 5px;
    padding: 10px 10px;
    width: 500px;
    border-radius: 25px;
    border: 1px solid white;
}

.ul-section-1 {
    padding: 0px 50px;
}

.individual-card>h5 {
    font-weight: 700;
    color: #ffffff;
    font-size: 22px;

}

.individual-card>p {
    color: #ffffff;
    font-size: 1.2rem;
    font-weight: 400;
    font-family: 'Montserrat';
}

.btn-section-one {
    height: 50px !important;
    font-weight: 700 !important;
    padding: 0px 50px !important;
    background-color: #c10000 !important;
    color: #ffffff !important;
    border: 1px solid #c10000 !important;
    font-size: 20px !important;
    border-radius: 25px !important;
    margin-bottom: 20px;
    margin-top: 25px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.btn-section-two {
    height: 50px !important;
    font-weight: 700 !important;
    padding: 0px 50px !important;
    background-color: #c10000 !important;
    color: #ffffff !important;
    border: 1px solid #c10000 !important;
    font-size: 20px !important;
    border-radius: 25px !important;
    /* margin-bottom: 20px; */
    /* margin-top: 30px; */
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.btn-section-four {
    height: 50px !important;
    font-weight: 700 !important;
    padding: 0px 50px !important;
    background-color: #c10000 !important;
    color: #ffffff !important;
    border: 1px solid #c10000 !important;
    font-size: 20px !important;
    border-radius: 25px !important;
    /* margin-bottom: 20px; */
    margin-top: 10px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.btn-section-seven {
    height: 50px !important;
    font-weight: 700 !important;
    padding: 0px 50px !important;
    background-color: #c10000 !important;
    color: #ffffff !important;
    border: 1px solid #c10000 !important;
    font-size: 20px !important;
    border-radius: 25px !important;
    margin-bottom: 20px;
    /* margin-top: 60px; */
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.btn-section-eight {
    height: 50px !important;
    font-weight: 700 !important;
    padding: 0px 50px !important;
    background-color: #c10000 !important;
    color: #ffffff !important;
    border: 1px solid #c10000 !important;
    font-size: 20px !important;
    border-radius: 25px !important;
    margin-bottom: 20px;
    /* margin-top: 60px; */
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

/* Section 2 */

.section-2 {
    background-color: #ffffff;
    padding: 110px 110px !important;
}

.section-2>.head {
    text-align: left;
}

.section-2>.head>h2 {
    font-size: 5.6rem;
    font-weight: 800;
    margin: 40px 0px;
    color: #1a67a3;
    font-family: 'Raleway';
}

.section-2>.head>h2>span {
    font-size: 4rem;
    font-weight: 700;
    font-family: 'Raleway';
}



.section-2>.head>strong {
    font-size: 1.5rem;
    font-weight: 800;
    color: #1a67a3;
    font-family: 'Raleway';
}


.section-2>.section-two-body {
    text-align: left;
    margin-top: 60px;
    /* padding: 0px 50px 0px 0px; */
}

.section-2>.section-two-body>.section-two-p {
    margin-bottom: 15px;
}

.section-2>.section-two-body>.section-two-p>div>p {
    color: #000000;
    font-size: 1rem;
    /* margin-top: 20px; */
    width: auto;
    padding: 0px 128px;
    font-weight: 500;
    line-height: 25px;
    text-align: justify;
    font-family: 'Montserrat';

}

.ul-section-2 {
    /* margin-left: 70px; */
    padding: 0px 188px 0px 0px !important;
}

li.list-section-2::marker {
    color: #fcc75c;
}

.section-2>.section-two-body-cards {
    margin: 40px 0px;
}

.section-two-body-cards>.individual-card {
    margin: 5px 5px;
    padding: 10px 10px;
    width: 370px;
    height: 80px;
    border: 1px solid black;
    border-radius: 25px;
}

.section-two-body-cards>.individual-card>h5 {
    font-weight: 700;
    color: #1a67a3;
    font-size: 22px;

}

.section-two-body-cards>.individual-card>p {
    color: black;
    font-size: 20px;
    font-weight: 500;
    font-family: 'Montserrat';
}

.section-2>.btn-section-one {
    height: 50px !important;
    font-weight: 700 !important;
    padding: 0px 50px !important;
    background-color: #c10000 !important;
    color: #ffffff !important;
    border: 1px solid #c10000 !important;
    font-size: 20px !important;
    border-radius: 25px !important;
    margin-bottom: 20px;
}

/* Section 3 */

.section-3 {
    background-color: #1a67a3;
    padding: 110px 110px !important;
}

.section-3>.head {
    text-align: center;
}

.ul-section-3 {
    margin-left: 70px;
}

.section-3>.head-section-3>h2 {
    font-size: 3.3rem;
    font-weight: 800;
    margin: 0px 10px 0px 0px;
    color: #ffffff;
    font-family: 'Montserrat';
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.section-3>.head-section-3>h5 {
    font-size: 1.3rem;
    font-weight: 700;
    margin: 25px 0px 0px 0px;
    color: #ffffff;
    font-family: 'Montserrat';
    letter-spacing: 1px !important;
}

.section-3>.head-section-3>h2>span {
    color: #fcc75c;
    font-family: 'Montserrat';
    font-size: 3.3rem;
}

.section-3>.head-section-3>strong {
    font-size: 1.5rem;
    font-weight: 800;
    color: #ffffff;
    font-family: 'Montserrat';
}


.section-3>.section-three-body {
    text-align: left;
    margin-top: 70px;
    /* padding: 0px 50px 0px 0px; */
}

.section-3>.section-three-body>.section-three-p>div>p {
    color: #ffffff;
    font-size: 1rem;
    /* margin-top: 20px; */
    width: auto;
    padding: 0px 128px;
    line-height: 25px;
    text-align: justify;
    font-family: 'Montserrat';

}

.section-3>.section-three-body-cards {
    margin: 40px 0px;
}

.section-three-body-cards>.individual-card {
    margin: 5px 5px;
    padding: 10px 10px;
    width: 370px;
    height: 80px;
    border: 1px solid black;
    border-radius: 25px;
}

.section-three-body-cards>.individual-card>h5 {
    font-weight: 700;
    color: #ffffff;
    font-size: 22px;

}

.section-three-body-cards>.individual-card>p {
    color: black;
    font-size: 20px;
    font-weight: 500;
    font-family: 'Montserrat';
}

.section-3>.btn-section-one {
    height: 50px !important;
    font-weight: 700 !important;
    padding: 0px 50px !important;
    background-color: #c10000 !important;
    color: #ffffff !important;
    border: 1px solid #c10000 !important;
    font-size: 20px !important;
    border-radius: 25px !important;
    margin-bottom: 20px;
}

/* section 4 */

.section-4 {
    background-color: #ffffff;
    padding: 120px 120px !important;
}

.section-4>.head-section-4 {
    text-align: center;
}

.section-4>.head-section-4>h2 {
    font-size: 3rem;
    font-weight: 800;
    /* margin: 40px 0px; */
    color: #1a67a3;
    text-align: center;
    font-family: 'Montserrat';
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.section-4>.head-section-4>h3 {
    font-size: 2rem;
    font-weight: 800;
    margin: 20px 0px 0px 0px;
    color: #1a67a3;
    text-align: center;
    font-family: 'Montserrat';

}

.section-4>.head-section-4>h5 {
    font-size: 1.3rem;
    font-weight: 800;
    margin: 30px 0px 0px 0px;
    color: #fcc75c;
    text-align: center;
    font-family: 'Montserrat';
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5);

}

.section-4>.head-section-4>strong {
    font-size: 1.5rem;
    font-weight: 800;
    color: #1a67a3;
    font-family: 'Montserrat';
}


.section-4>.section-four-body {
    text-align: center;
    margin-top: 25px;
    /* padding: 0px 50px 0px 0px; */
}

.section-4>.section-four-body>.section-four-p>p {
    color: #000000;
    font-size: 1rem;
    margin-top: 20px;
    width: 80%;
    padding: 0px 50px;
    text-align: justify;
    font-weight: 500;
    font-family: 'Montserrat';

}

.section-4>.section-four-body-cards {
    margin: 40px 0px;
}

.section-four-body-cards>.individual-card {
    margin: 5px 5px;
    padding: 10px 10px;
    width: 370px;
    height: 80px;
    border: 1px solid black;
    border-radius: 25px;
}

.section-four-body-cards>.individual-card>h5 {
    font-weight: 700;
    color: #1a67a3;
    font-size: 22px;

}

.section-four-body-cards>.individual-card>p {
    color: black;
    font-size: 20px;
    font-weight: 500;
    font-family: 'Montserrat';
}

.section-4>.btn-section-one {
    height: 50px !important;
    font-weight: 700 !important;
    padding: 0px 50px !important;
    background-color: #c10000 !important;
    color: #ffffff !important;
    border: 1px solid #c10000 !important;
    font-size: 20px !important;
    border-radius: 25px !important;
    margin-bottom: 20px;
}

/* section 5 */

.section-5 {
    background-color: #1a67a3;
    padding: 110px 110px !important;
}

.section-5>.head-col {
    text-align: left;
    /* padding: 0px 70px 0px 170px; */
    ;
}

.section-5>.head-col>h2 {
    font-size: 3.3rem;
    font-weight: 800;
    margin: 0px 0px 0px 0px;
    color: #ffffff;
    font-family: 'Montserrat';
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.section-5>.head-col>h3 {
    font-size: 2rem;
    font-weight: 800;
    margin: 20px 0px 0px 0px;
    color: #ffffff;
    text-align: center;
    font-family: 'Montserrat';

}

.section-5>.head-col>h5 {
    font-size: 1.3rem;
    font-weight: 800;
    margin: 30px 0px 0px 0px;
    color: #ffffff;
    text-align: center;
    font-family: 'Montserrat';


}

.section-5>.head-col>h2>strong {
    color: #1a67a3;
    background-color: white;
}

.section-5>.head-col>strong {
    font-size: 1.35rem;
    font-weight: 800;
    color: #ffffff;
    font-family: 'Montserrat';
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}


.section-5>.head-col>.section-five-body {
    text-align: left;
    margin-top: 25px;
    /* padding: 0px 50px 0px 0px; */
}

.section-5>.head-col>.section-five-body>p {
    text-align: justify;
    color: white;
    font-size: 16px;
    padding: 0px 90px;
    font-family: 'Montserrat';
}

.section-5>.section-five-body>.section-five-p {
    /* padding: 40px 140px 0px 0px; */
}

.section-5>.section-five-body>.section-five-p>p {
    color: #ffffff;
    font-size: 1.2rem;
    margin-top: 20px;
    width: 50%;
    padding: 0px 50px;
    font-family: 'Montserrat';

}

.section-5>.section-five-body-cards {
    margin: 40px 0px;
}

.section-five-body>h5 {
    font-size: 20px;
    text-align: left;
    font-weight: 800;
    margin: 20px 0px;
    color: white;
    width: 100%;
    font-family: 'Montserrat';


}

.section-five-body-cards>.individual-card {
    margin: 5px 5px;
    padding: 10px 10px;
    width: 370px;
    height: 80px;
    border: 1px solid black;
    border-radius: 25px;
}

.section-five-body-cards>.individual-card>h5 {
    font-weight: 700;
    color: #ffffff;
    font-size: 22px;

}

.section-five-body-cards>.individual-card>p {
    color: black;
    font-size: 20px;
    font-weight: 500;
    font-family: 'Montserrat';
}

.section-5>.btn-section-one {
    height: 50px !important;
    font-weight: 700 !important;
    padding: 0px 50px !important;
    background-color: #c10000 !important;
    color: #ffffff !important;
    border: 1px solid #c10000 !important;
    font-size: 20px !important;
    border-radius: 25px !important;
    margin-bottom: 20px;
}

.card-section-five {
    border: 1px solid white;
    /* margin: 0px 5px; */
    padding: 15px;
    border-radius: 15px;
    height: 250px;
    /* width: 550px; */
    /* margin-bottom: 5px; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    background-color: rgba(255, 255, 255);
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);

}

.card-section-five>strong {
    color: #1a67a3;
    font-family: 'Montserrat';
    font-size: 1rem;

}

.list-section-five {
    list-style: none;
}

.footer-section-5 {
    font-weight: 700;
    color: #ffffff;
    font-size: 22px;
    font-family: 'Montserrat';
}

/* section risk */

.section-risk {
    background-color: #ffffff;
    padding: 110px 110px !important;
}

.section-risk>.head-risk {
    text-align: center;
}

.section-risk>.head-risk>h2 {
    font-size: 5.2rem;
    font-weight: 800;
    margin: 40px 0px;
    color: #1a67a3;
    text-align: center;
    line-height: 70px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    font-family: 'Montserrat';
}

.section-risk>.head-risk>h2>span {
    font-size: 4.8rem;
    font-weight: 500;
    font-family: 'Montserrat';

}

.section-risk>.head-risk>strong {
    font-size: 1rem;
    font-weight: 600;
    color: #1a67a3;
    font-family: 'Montserrat';
    padding: 20px 240px;
    display: block;
}

.display-md {
    display: block !important;
}

.display-xs {
    display: none !important;
}

/* section 6 */


.section-6 {
    background-color: #1a67a3;
    padding: 110px 110px !important;
}

.section-6>.head-section-6 {
    text-align: center;
}

.section-6>.section-six-body>h2 {
    font-size: 2.8rem;
    font-weight: 800;
    margin: 0px 0px 0px 0px;
    color: #ffffff;
    font-family: 'Montserrat';
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.section-6>.head-section-6>h2 {
    font-size: 4.8rem;
    font-weight: 800;
    margin: 40px 0px;
    color: #ffffff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.section-6>.head-section-6>strong {
    font-size: 1.5rem;
    font-weight: 800;
    color: #ffffff;
    font-family: 'Montserrat';
}


.section-6>.section-six-body {
    text-align: center;
    margin-top: 25px;
    /* padding: 0px 50px 0px 0px; */
}

.section-6>.section-six-body>.section-six-p>p {
    color: #ffffff;
    font-size: 1.2rem;
    /* margin-top: 20px; */
    width: 80%;
    padding: 0px 50px;
    text-align: center;
    font-weight: 500;
    font-family: 'Montserrat';

}

.core-tittle {
    margin-top: 30px;
    color: #fcc75c !important;
    justify-content: center;
    font-size: 25px !important;
    font-weight: 700 !important;
}

li.list-section-6::marker {
    color: #fcc75c !important;
}

.section-6>.section-six-body-cards {
    margin: 40px 0px;
}

.section-six-body-cards>.individual-card {
    margin: 5px 5px;
    padding: 10px 10px;
    width: 370px;
    height: 80px;
    border: 1px solid black;
    border-radius: 25px;
}

.section-six-body-cards>.individual-card>h5 {
    font-weight: 700;
    color: #ffffff;
    font-size: 22px;

}

.section-six-body-cards>.individual-card>p {
    color: black;
    font-size: 20px;
    font-weight: 500;
    font-family: 'Montserrat';
}

.section-6>.btn-section-one {
    height: 50px !important;
    font-weight: 700 !important;
    padding: 0px 50px !important;
    background-color: #c10000 !important;
    color: #ffffff !important;
    border: 1px solid #c10000 !important;
    font-size: 20px !important;
    border-radius: 25px !important;
    margin-bottom: 20px;
}


/* section 7 */


.section-7 {
    background-color: #ffffff;
    padding: 110px 110px !important;
}

.section-7>.head {
    text-align: center;
}

.section-7>.head>h2 {
    font-size: 3.3rem;
    font-weight: 800;
    margin: 0px 0px 40px 0px;
    color: #1a67a3;
}

.section-7>.head>strong {
    font-size: 1.5rem;
    font-weight: 800;
    color: #1a67a3;
    font-family: 'Montserrat';
}


.section-7>.section-seven-body {
    text-align: center;
    margin-top: 25px;
    /* padding: 0px 50px 0px 0px; */
}

.section-7>.section-seven-body>.section-seven-p>p {
    color: #1a67a3;
    font-size: 1.2rem;
    margin-top: 20px;
    width: 80%;
    padding: 0px 50px;
    text-align: justify;
    font-weight: 500;
    font-family: 'Montserrat';

}

.section-7>.section-seven-body-cards {
    margin: 40px 0px;
}

.section-seven-body-cards>.individual-card {
    margin: 5px 5px;
    padding: 10px 10px;
    width: 370px;
    height: 80px;
    border: 1px solid black;
    border-radius: 25px;
}

.section-seven-body-cards>.individual-card>h5 {
    font-weight: 700;
    color: #ffffff;
    font-size: 22px;

}

.section-seven-body-cards>.individual-card>p {
    color: black;
    font-size: 20px;
    font-weight: 500;
    font-family: 'Montserrat';
}

.section-7>.btn-section-one {
    height: 50px !important;
    font-weight: 700 !important;
    padding: 0px 50px !important;
    background-color: #c10000 !important;
    color: #ffffff !important;
    border: 1px solid #c10000 !important;
    font-size: 20px !important;
    border-radius: 25px !important;
    margin-bottom: 20px;
}

/* Section 8 */

.section-8 {
    background-color: #ffffff;
    padding: 110px 110px !important;
}

.section-8>.head-section-8 {
    text-align: center;
}

.section-8>.head-section-8>h2 {
    font-size: 3.3rem;
    font-weight: 800;
    margin: 0px 0px 20px 0px;
    color: #1a67a3;
    text-align: center;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    font-family: 'Montserrat';
}

.section-8>.head-section-8>h3 {
    font-size: 2rem;
    font-weight: 800;
    margin: 20px 0px 0px 0px;
    color: #1a67a3;
    text-align: center;
    font-family: 'Montserrat';

}

.section-8>.head-section-8>h5 {
    font-size: 1.3rem;
    font-weight: 800;
    margin: 30px 0px 0px 0px;
    color: #000000;
    text-align: center;
    font-family: 'Montserrat';


}

.section-8>.head-section-8>strong {
    font-size: 1.8rem;
    font-weight: 800;
    color: #1a67a3;
    font-family: 'Montserrat';
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}


.section-8>.section-eight-body {
    text-align: center;
    margin-top: 25px;
    /* padding: 0px 50px 0px 0px; */
}

.section-8>.section-eight-body>.section-eight-p>p {
    color: #ffffff;
    background-color: #1a67a3;
    font-size: 1.5rem;
    /* margin-top: 20px; */
    /* width: 80%; */
    padding: 30px 50px;
    font-family: 'Montserrat';

}

.section-eight-footer-p {
    color: #000000;
    font-size: 16px;
    /* margin-top: 20px; */
    /* width: 80%; */
    padding: 30px 50px;
    font-family: 'Montserrat';
}

.section-eight-footer-p-action {
    color: #000000;
    font-size: 18px;
    /* margin-top: 20px; */
    /* width: 80%; */
    /* padding: 30px 50px; */
    font-family: 'Montserrat';
}

.body-membership {
    color: #000000 !important;
    font-size: 16px !important;
    text-align: justify !important;
    height: 250px !important;
    font-family: 'Montserrat';
}


.section-8>.section-eight-body-cards {
    margin: 40px 0px;
}

.section-eight-body-cards>.individual-card {
    margin: 5px 5px;
    padding: 10px 10px;
    width: 370px;
    height: 80px;
    border: 1px solid black;
    border-radius: 25px;
}

.section-eight-body-cards>.individual-card>h5 {
    font-weight: 700;
    color: #1a67a3;
    font-size: 22px;

}

.section-eight-body-cards>.individual-card>p {
    color: black;
    font-size: 20px;
    font-weight: 500;
    font-family: 'Montserrat';
}

.section-8>.btn-section-one {
    height: 50px !important;
    font-weight: 700 !important;
    padding: 0px 50px !important;
    background-color: #c10000 !important;
    color: #ffffff !important;
    border: 1px solid #c10000 !important;
    font-size: 20px !important;
    border-radius: 25px !important;
    margin-bottom: 20px;
}

.section-eight-second-p {
    width: 60% !important;
    text-align: justify;
    background-color: #ffffff !important;
    color: #1a67a3 !important;
    font-family: 'Montserrat';
    font-size: 1.2rem !important;
    padding: 0px !important;
}

.list-section-eight {

    width: 60%;

    /* list-style: none; */
}

.list-section-eight>li {
    color: #000000;

}

li.list-section-8::marker {
    color: #fcc75c;
}

.title-membership {
    font-size: 1.3rem;
    font-weight: 800;
    color: #ffffff;
}

.ul-section-1 {
    text-align: left;
}

ul {
    list-style-type: disclosure-closed;
}

.list-section-1 {
    text-align: left;
    color: white;
    /* margin-top: 25px; */
    margin-bottom: 15px;
    font-size: 1rem;
    font-family: 'Montserrat';

}

.list-section-2 {
    text-align: left;
    color: #000000;
    /* margin-top: 25px; */
    margin-bottom: 15px;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 0.02rem;
    font-family: 'Montserrat';
}

.list-section-3 {
    text-align: left;
    color: #ffffff;
    /* margin-top: 25px; */
    margin-bottom: 15px;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 0.02rem;
    font-family: 'Montserrat';
}

li.list-section-3::marker {
    color: #fcc75c;
}

.list-section-4 {
    text-align: justify;
    color: #000000;
    margin-top: 25px;
    font-size: 1rem;
    font-weight: 500;
    font-family: 'Montserrat';
}

li.list-section-4::marker {
    color: #fcc75c;
}

.list-section-5 {
    text-align: justify;
    color: #000000;
    margin-top: 15px;
    font-size: 1rem;
    font-family: 'Montserrat';

}

.title-card-section-5 {
    font-size: 22px;
    color: #fcc75c;
    text-align: center;
    margin-bottom: 25px;
}

.list-section-5>i {
    font-weight: 600;
}

.list-section-5>b {
    font-weight: 700;
}

.list-section-6 {
    text-align: justify;
    color: white;
    margin-top: 25px;
    font-size: 16px;
    font-family: 'Montserrat';

}

.list-section-8 {
    text-align: justify;
    color: #1a67a3;
    margin-top: 25px;
    font-size: 16px;
    font-family: 'Montserrat';

}

.list-section-six {
    width: 50%;
}

.list-section-four {
    width: 55%;
}

.react-dropdown-tree-select .dropdown>a {
    width: 100% !important;
}

.react-dropdown-tree-select .dropdown, .dropleft, .dropright, .dropup {
    width: 100% !important;
}

.dropdown>.dropdown-content {
    /* position: absolute;
    top: 31px;
    width: 100%;
    padding: 10px 10px;
    height: 300px;
    overflow-y: scroll; */
    position: fixed !important;
    top: 36% !important;
    left: 36% !important;
    width: auto !important;
    padding: 10px 10px !important;
    height: 300px !important;
    overflow-y: scroll !important;
    border: 2px solid #1a67a3 !important;
}

.dropdown>.dropdown-content .search {
    width: 100% !important;
}

i.toggle.expanded::after {
    content: "-" !important;
    font-size: 25px !important;
    margin-right: 12px !important;
}

i.toggle.collapsed::after {
    content: "+" !important;
    font-size: 18px !important;
    margin-right: 12px !important;
}

input.checkbox-item {
    margin-right: 12px !important;
}

span.node-label {
    font-size: 14px !important;
}

ul.root {
    min-height: 300px !important;
}

li.tag-item::marker {
    content: "" !important;
}

li.tag-item:last-child {
    border: 1px solid #bebebe !important;
    padding: 10px !important;
    border-radius: 5px !important;
    color: lightslategray !important;
    width: 100%;
    background-color: white !important;
}

span.tag {
    margin: 1px 0px !important;
}

span.tag>button {
    background-color: whitesmoke !important;
    border: 0px !important;
}

.label-form-customer {
    color: rgb(255, 255, 255) !important;
    font-weight: 500 !important;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.bottom:after {
    content: "" !important;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.top:after {
    content: "" !important;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger {
    border: 0px !important;
    display: contents !important;
}

.tag-item .search {
    border: 0px !important;
}

.bg-modal-customers {
    background-color: rgba(0, 0, 0, 0.8) !important;
}

.bg-modal-customers .modal-dialog .modal-content {
    background-color: #1a67a3 !important;
}

.check-customers-input>label {
    color: white !important;
}


.form-customer>label {
    color: white;
}

.btn-join-builder-modal {
    border: 3px solid #c10000 !important;
    background-color: #f5f6ea !important;
    color: #c10000 !important;
    font-weight: 700 !important;
    font-size: 20px !important;
    width: 70% !important;
    border-radius: 30px !important;

}

.btn-join-builder-modal::hover {
    background-color: #c10000 !important;
    color: #f5f6ea !important;
    border-color: #c10000 !important;
}

.btn-join-builder-modal .spinner-grow-sm {
    width: 1rem !important;
    height: 1rem !important;
    margin-top: 1px !important;
    margin-right: 5px !important;
}

@media (max-width: 767px) {

    .section-1 {
        background-color: #1a67a3;
        padding: 0px 0px !important;
    }

    .section-1>.head {
        padding: 0px !important;
    }

    .head>.head-col {
        padding: 0px 10px;
    }

    .btn-section-one {
        width: auto;
        padding: 0px 20px !important;
    }

    .head>.head-col>h5 {
        text-align: center;
    }

    .section-one-body {

        padding: 0px 10px;

    }

    .section-one-body>.section-one-body-cards>p {
        width: auto;
        font-family: 'Montserrat';
    }

    .section-one-body-cards>ul {
        padding: 0px 50px;
    }

    .section-2 {
        margin: 25px 0px;
        padding: 0px 10px !important;
    }

    .head-section-2>h2 {
        font-size: 2.5rem;
        text-align: center;

    }

    .head-section-2>strong {
        font-size: 1.5rem !important;

    }

    .section-2>.section-two-body>.section-two-p>div>p {
        width: auto;
        padding: 0px 10px;
        font-family: 'Montserrat';
    }

    .ul-section-2 {
        /* margin-left: 70px; */
        padding: 0px 45px !important;
    }

    .list-section-2 {
        text-align: justify !important;
    }

    .btn-section-two {
        padding: 0px 10px !important;

    }

    .section-3 {

        padding: 20px 10px !important;
    }

    .section-3>.head-section-3>h2 {
        font-size: 2.5rem;
        margin: 0px
    }

    .section-3>.section-three-body>.section-three-p>div>p {
        width: auto;
        padding: 0px 10px;
        font-family: 'Montserrat';
    }

    .list-section-3 {
        text-align: justify !important;
    }

    .section-4 {
        padding: 20px 10px !important;
    }

    .section-4>.head-section-4>h3 {
        font-size: 1.5rem;
    }

    .section-4>.head-section-4>h5 {
        font-size: 1.2rem;
    }

    .section-4>.section-four-body>.section-four-p>p {

        width: 100%;
        padding: 0px 20px;
        font-family: 'Montserrat';
    }

    .list-section-four {
        width: 100%;
        padding: 0px 40px;
    }

    .section-5 {
        padding: 20px 10px !important;
    }

    .section-5>.head-col>.section-five-body>p {

        font-size: 1.2rem;
        padding: 0px 20px;
        font-family: 'Montserrat';
    }

    .section-risk {
        padding: 20px 10px !important;
    }

    .section-risk>.head-risk>h2 {
        font-size: 2.5rem;
        line-height: 40px;
    }

    .section-risk>.head-risk>strong {
        padding: 0px 10px;
        text-align: justify;
        font-family: 'Montserrat';

    }

    .section-6 {
        padding: 20px 10px !important;
    }

    .section-6>.section-six-body>.section-six-p>p {

        width: 100%;
        padding: 0px 10px;
        text-align: justify;
        font-size: 16px;
        font-family: 'Montserrat';
    }

    .list-section-6 {
        font-size: 16px;
    }

    .core-tittle {
        text-align: center !important;
    }

    .list-section-six {
        width: 100%;
        padding: 0px 40px;
    }

    .section-8 {
        padding: 20px 10px !important;
    }

    .section-8>.head-section-8>h2 {
        font-size: 2.5rem;
    }

    .section-8>.head-section-8>h3 {
        font-size: 1.5rem;
    }

    .section-8>.head-section-8>h5 {
        font-size: 1.2rem;
    }

    .list-section-8 {
        font-size: 16px !important;
    }

    .body-membership-1 {
        height: 250px !important;
        padding: 20px 20px !important;
        font-size: 16px !important;
    }

    .body-membership-2 {
        height: 300px !important;
        padding: 20px 20px !important;
        font-size: 16px !important;
    }

    .list-section-eight {
        width: 100%;
        padding: 0px 40px !important;
        /* list-style: none; */
    }


    .section-eight-footer-p {

        padding: 30px 20px;
        text-align: justify;
        font-size: 16px;

    }

    .section-eight-footer-p-action {
        padding: 0px 20px;
        text-align: justify !important;
        font-size: 16px;
    }

    .individual-card {
        margin: 10px 0px;
        padding: 30px 30px;
        width: 100%;
    }

    .display-md {
        display: none !important;
    }

    .display-xs {
        display: block !important;
    }

    .dropdown>.dropdown-content {
        position: fixed !important;
        top: 50% !important;

        left: 50% !important;
        width: 90% !important;
        padding: 10px 30px !important;
        height: 300px !important;
        overflow-y: scroll !important;
        border: 2px solid #1a67a3 !important;

        -webkit-transform: translate(-50%, -50%);
        transform: translate(-48%, -50%);
    }


}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .ul-section-2 {
        padding: 0px 188px 0px 188px !important;
    }

    .section-risk>.head-risk>strong {
        padding: 0px 50px;
    }

    .dropdown>.dropdown-content {
        top: 16% !important;
        left: 28% !important;
    }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .section-two-p {
        padding: 0px 30px !important;
    }

    .section-three-p {
        padding: 0px 30px !important;
    }

    .section-four-p {
        padding: 0px 30px !important;
    }

    .section-five-p {
        padding: 0px 120px !important;
    }

    .section-risk {
        padding: 0px 30px !important;
    }

    .section-six-p {
        padding: 0px 30px !important;
    }

    .section-eight-p {
        padding: 0px 120px !important;
    }

    .list-section-eight {
        padding: 0px 130px !important;
    }

    .section-eight-footer-p {
        padding: 10px 30px !important;
    }

    .section-eight-footer-p-action {
        padding: 10px 30px !important;
    }

    .ul-section-2 {
        padding: 10px 120px 0px 120px !important;
    }

    .head-col {
        padding: 0px 50px 0px 50px !important;
    }

    .head-col>h1, h2, h3, h5 {
        text-align: center !important;
    }
}