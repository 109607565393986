.footerBanner {
    background-color: #1a67a3 !important
}

.responsiveGridLast {
    text-align: end !important
}


.blob-btn {
    text-align: center !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    color: #fcc75c !important;
    padding: 9px 16px !important;
    line-height: normal !important;
    border-radius: 7px !important;
    width: auto !important;
    margin: 0 !important;
    border: solid 1px #fcc75c !important;
}

.blob-btn:hover {
    background: #fcc75c !important;
    transition: 0.3s !important;
    color: #1a67a3 !important;
}

.social-link-btn {
    color: #b0b3b8 !important;
    font-size: 17px !important;
    background: #201e3e !important;
    width: 30px !important;
    height: 30px !important;
    padding: 5px 3px !important;
    border-radius: 5px !important;
    display: block !important;
    line-height: normal !important;
    margin: 0px 2px;
}

.social-link-btn:hover {
    color: white !important;
}

.social-box {
    display: flex;
    flex-direction: row;
    gap: 10;
    text-align: center;
    justify-content: center;
    list-style: none;

}

.img-header {
    width: 75px !important
}

.tittle-company {
    font-size: 22px;
    color: white;
    font-family: Montserrat, sans-serif;
    line-height: 1.5;
}

.logo-text>small {
    font-size: 13px;
    font-weight: 600;
    color: #fcc75c;
    font-family: Montserrat;
}

.small-footer {
    color: white;
    font-size: 13px;
    font-family: Montserrat;
}

.small-footer>a {
    color: white;
}

.useful-links>h5 {
    font-family: Montserrat;
}

.social-links-footer>h5 {
    font-family: Montserrat;
}

.footer-grid {
    margin-top: auto;
    padding: 24px 110px;
    background-color: #1a67a3;
}

.modal-feedback{
    background-color: rgba(0, 0, 0, 0.7);
}

.modal-feedback>.modal-dialog>.modal-content{
    padding: 15px 15px;
    margin: 0px!important;
}

.container-feedback {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.feedback-header{
    margin: 0px!important;
    padding: 0px 15px!important;
    border:0px!important;
    
}

.feedback-tittle>h3{
    font-family: "monserrat";
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    margin: 0px;
    letter-spacing: 1px;
    color: #154e79;
}

.feedback-tittle>span{
    font-family: "monserrat";
    font-size: 17px; 
    color: #154e79;
    font-weight: 600;   
}

.feedback-label{
    color: #898989 !important;
    font-family: "monserrat";
    font-weight: 600!important;
}

.checkbox-feedback {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    margin: 25px 0px 10px 0px;
}

.input-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.input-with-icon {
    position: relative;
}

.input-with-icon input {
    padding-left: 40px !important;
    font-family: "monserrat";
    font-weight: 600 !important;
}

.input-with-icon input::placeholder {
    color: #aaa !important;
}

.input-with-icon .area-comment {
    padding-left: 40px !important;
    font-family: "monserrat";
    font-weight: 600 !important;
}

.input-with-icon .area-comment::placeholder {
    color: #aaa !important;
    position: relative;
    top: 22px;
}

.input-with-icon .icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #aaa;
}

.btn-submit-feedback{
    width: 100%;
    font-family: "monserrat";
    background-color: #1a67a3!important;
    font-size: 20px!important;
    font-weight: 600!important;
}

.feedback-header>.close span{
    color: #fcc75c;
}



@media (min-width: 640px) {
    .responsiveGridFirst {
        text-align: center !important
    }

    .responsiveGridLast {
        text-align: center !important
    }

}

@media (max-width: 768px) {

    .footer-grid {
        margin-top: auto;
        padding: 24px 20px;
        background-color: #1a67a3;
    }

    .logo-text>small {
        font-size: 10px;
    }

    .tittle-company {
        font-size: 18px;
    }

    .responsiveGridFirst {
        text-align: center !important
    }
}