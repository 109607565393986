.home-container {}

.home-tittle-main h1 {
    font-size: 3rem;
    font-weight: 700;
}

.home-carousel {
    position: relative;
    width: 75%;
}

.home-carousel h1 {
    color: #1a67a3;
    font-size: 4rem;
    font-weight: 700;
}

.home-fixed-header-carousel span {
    color: #1a67a3;
    font-weight: 500;
}

.home-carousel-2 {
    position: relative;
    height: auto;
    padding: 0px 150px 0px 150px;
}

.home-third-content {
    padding: 0px 150px 0px 150px;
}

.home-four-content {
    padding: 0px 150px 0px 150px;
}

.home-five-content {
    padding: 0px 150px 0px 150px;
}

.home-six-content {
    padding: 0px 150px 0px 150px;
}


.home-fixed-header-carousel {
    position: absolute;
    bottom: 18px;
    left: 50px;
}

.home-fixed-header-carousel {
    /* width: 100%; */
}

.home-img-details {
    height: 32rem;
    padding: 0px 15px;
}

.home-tittle h1 {
    color: #000000;
    font-weight: 600;
}

.home-carousel-2 h2 {
    color: #1a67a3;
    font-weight: 600;
}

.home-carousel-2 p {
    color: #000000;
    font-size: 18px;
    font-weight: 600;
    text-align: justify;
}

.home-carousel-2 button {
    color: #1a67a3 !important;
    border: 2px solid #1a67a3;
    border-radius: 40px;
    background-color: #f8f9fa !important;
    font-size: 18px;
    font-weight: 600;
}

.home-carousel-2 .btn.active {
    color: #ffffff !important;
    border: 2px solid #1a67a3 !important;
    border-radius: 40px;
    background-color: #1a67a3 !important;
    font-size: 18px;
    font-weight: 600;
}

.home-carousel-2 .btn:hover {
    color: #1a67a3 !important;
}

.home-carousel-2 .btn.active:hover {
    color: #ffffff !important;
}

.home-third-content h2 {
    color: #1a67a3;
    font-weight: 600;
}

.home-four-content h2 {
    color: #1a67a3;
    font-weight: 600;
}

.home-five-content h2 {
    color: #1a67a3;
    font-weight: 600;
}

.home-six-content h2 {
    color: #1a67a3;
    font-weight: 600;
}

.home-six-content h5 {
    color: #fcc75c;
    font-weight: 600;
    text-align: center;
}

.home-six-content p {
    color: #ffffff;
    font-weight: 700;
    text-align: center;
}

.home-paragraph {
    padding: 0px 50px 0px 50px;
    text-align: center;
    font-size: 18px;
    line-height: 2rem;
    font-weight: 500;
}

.carousel-buttons {
    width: 25%;
}

.img-five-content{
    width: 50%;
}

.btn-content{
    width: 25%;
}

.btn-header{
    width: 50%;
}

@media (max-width: 768px) {

    .home-container {
        width: 100%;
    }

    .home-tittle-main h1 {
        font-size: 1.5rem;
        font-weight: 600;
        color: white;
        background-color: #1a67a3;
    }

    .home-tittle h1 {
        font-size: 2rem;
        font-weight: 500;

    }

    .home-fixed-header-carousel {
        position: initial;
        width: 100%;
    }


    .home-carousel {
        position: relative;
        width: 100%;
    }

    .home-carousel h1 {
        color: #1a67a3;
        font-size: 3rem;
        font-weight: 700;
    }

    .carousel-buttons {
        width: 100%;
    }

    .home-carousel-2 {
        position: relative;
        padding: 0px 25px;
       
    }

    .home-carousel-2 .carousel-item {
        height: 22rem!important;
    }

    .home-third-content {
        padding: 0px 25px;
    }
    .home-four-content {
        padding: 0px 25px;
    }
    .home-five-content {
        padding: 0px 25px;
    }

    .home-paragraph {
        padding: 0px 15px 0px 15px;
        text-align: justify!important;
    }

    .img-five-content{
        width: 100%;
    }

    .btn-content{
        width: 100%;
    }
    .btn-header{
        width: 100%;
    }
    

}